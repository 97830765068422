import React, { useState, useEffect, useContext } from "react";
import { IonContent, IonHeader, IonPage, IonRouterLink } from "@ionic/react";
import "./Start.css";

import User, { UserContext } from "../components/user";
import { IUserData } from "../components/user/user";
import CityView from "./CityView";

import * as routes from "../constants/routes";

const Start: React.FC = () => {
  const user: User = useContext(UserContext);

  const [cityViewNickname, setCityViewNickname] = useState("");

  useEffect(() => {
    if (user && user.isLoggedIn === false) {
      user.firebase.getRandomCityUser().then((user: IUserData) => {
        if (user) setCityViewNickname(user.username);
      });
    }
  }, []);

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent className="start-screen">
        {cityViewNickname ? (
          <CityView nickname={cityViewNickname} hideUI={true} />
        ) : (
          <div></div>
        )}
        <div className="start-group">
          <div className="start-logo-div">
            <img
              className="start-logo"
              src={require("../images/logo-white-border.svg")}
            ></img>
          </div>

          <div className="type-logo-div">
            <img
              className="type-logo"
              src={require("../images/BlockCities-type-logo.svg")}
            ></img>
          </div>

          <p className="headline-text">Build Tiny Cities</p>
          <IonRouterLink routerLink={routes.SIGNIN} routerDirection="forward">
            <div className="signin-button-outline">
              <div className="signin-button">I already have an account</div>
            </div>
          </IonRouterLink>

          <IonRouterLink routerLink={routes.SIGNUP} routerDirection="forward">
            <div className="signup-button-outline">
              <div className="signup-button">Sign Up</div>
            </div>
          </IonRouterLink>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Start;
