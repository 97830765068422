import React, { useContext } from "react";

import User, { UserContext } from "../../components/user";
import { Redirect, Route, RouteProps } from "react-router";

import * as routes from "../../constants/routes";

declare var window: any;

export interface PrivateRouteProps extends RouteProps {
  acceptEthereum?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const user: User = useContext(UserContext);

  if (
    user.isLoggedIn ||
    (props.acceptEthereum === true && window.ethereum !== undefined)
  ) {
    return <Route {...props} />;
  } else {
    return <Route render={() => <Redirect to={routes.START} />} />;
  }
};

export default PrivateRoute;
