import React, { useState, useContext, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonModal,
  IonGrid,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonCol,
  IonRow,
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import "./Wallet.css";

import { Swipeable } from "react-swipeable";

import User, { UserContext } from "../components/user";
import { IBalance } from "../components/user/user";

import * as routes from "../constants/routes";

const Wallet: React.FC = () => {
  const user: User = useContext(UserContext);

  // const [showSendModal, setShowSendModal] = useState(false);
  // const [sendAddress, setSendAddress] = useState("");
  // const [sendAmount, setSendAmount] = useState("");
  const [balance, setBalance] = useState({
    eth: 0.0,
    usd: 0.0,
    ethStr: "",
    usdStr: "",
  });

  useEffect(() => {
    user.getBalance().then((balance: IBalance) => {
      setBalance(balance);
    });
  }, []);

  return (
    <IonPage>
      <IonContent scroll-y="true">
        {/* <IonModal
          id="modal-build-building"
          backdropDismiss={true}
          isOpen={showSendModal}
          onDidDismiss={() => setShowSendModal(false)}
        >
          <Swipeable
            onSwipedDown={() => setShowSendModal(false)}
            trackMouse={true}
          >
            <IonList lines="full" class="enable-pointer-events">
              <IonItem>
                <IonLabel position="stacked">Address</IonLabel>
                <IonInput
                  required
                  type="text"
                  onIonChange={e => {
                    setSendAddress(e.detail.value);
                  }}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Amount</IonLabel>
                <IonInput
                  required
                  type="number"
                  min="0"
                  step="0.1"
                  value="0.0"
                  onIonChange={e => {
                    setSendAmount(e.detail.value);
                  }}
                ></IonInput>
              </IonItem>
              <div
                className="half-width-button grey"
                onClick={async () => {
                  setShowSendModal(false);
                  await user.walletSend(sendAddress, sendAmount);
                }}
              >
                <p className="button-text-large">Proceed</p>
              </div>
            </IonList>
          </Swipeable>
        </IonModal> */}
        <div className="max-width">
          <IonHeader className="header">
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="2" className="no-padding">
                  <IonRouterLink
                    routerLink={routes.USER}
                    routerDirection="back"
                  >
                    <IonButton className="back-button no-padding">
                      <img
                        className="back-arrow"
                        src={require("../images/x-icon.svg")}
                      ></img>
                    </IonButton>
                  </IonRouterLink>
                </IonCol>

                <IonCol size="8">
                  <h3 className="page-header">My Wallet</h3>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>

          <p className="wallet-price">~${balance.usdStr}</p>
          <p className="eth-amount">{balance.ethStr} ETH</p>
          {/* <p className="wallet-address">
            <div className="label">Wallet address:</div>
            <br></br>
            {user.current.publicAddress}
          </p> */}

          <IonGrid>
            {user.current.private.provider === "fortmatic" ? (
              <IonRow>
                <IonCol size="6">
                  <div
                    className="half-width-button grey"
                    //onClick={() => setShowSendModal(true)}
                    onClick={() => {
                      if (user.current.private.provider === "fortmatic") {
                        user.fm.transactions.send(null, () => {});
                      }
                    }}
                  >
                    <p className="button-text-large">Send</p>
                  </div>
                </IonCol>

                <IonCol size="6">
                  <div
                    className="half-width-button"
                    onClick={() => {
                      if (user.current.private.provider === "fortmatic") {
                        user.fm.user.deposit();
                      }
                    }}
                  >
                    <p className="button-text-large">Add</p>
                  </div>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow></IonRow>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Wallet;
