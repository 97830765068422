import Web3 from "web3";
import { ethers } from "ethers";

import Firebase from "../firebase";

import User from "../user";
import { Web3Provider } from "ethers/providers";

const bcContracts = require("blockcities-contract-artifacts");

class BuildMachine {
  private firebase: Firebase;

  private user: User;
  public buildings: any;

  private web3: Web3;
  private prepared: boolean = false;

  private etherProvider: Web3Provider;
  private signer: any;

  public networkID: number = 1;

  public vendingContract: ethers.Contract;
  public cityBuildingValidator: ethers.Contract;
  public limitedVendingContract: ethers.Contract;

  public ethToUsd: number = 0.0;
  public mintedTokens: any;

  // static blockCitiesAddresses: any = {
  //   mainnet: "0x2f2d5aA0EfDB9Ca3C9bB789693d06BEBEa88792F",
  //   ropsten: "0x86eD0a82dDc2EdEA8cC4Bc023eC2a4079DAB42c9",
  //   rinkeby: "0x74b8D7E2b681d1C4f13bd8722937A722bCc7A4F3",
  //   local: "0x70D0C5f857C0C57190566d45AaF53234b65B8bE9",
  // };

  constructor(firebase: Firebase) {
    this.firebase = firebase;
  }

  setUser(user: User) {
    this.user = user;
  }

  prepareBuildings() {
    return new Promise((resolve) => {
      const firestore: firebase.firestore.Firestore = this.firebase.firestore;

      this.buildings = {};

      firestore
        .collection("data")
        .doc("mainnet")
        .collection("buildings")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.buildings[doc.id] = doc.data();
            this.buildings[doc.id].id = parseInt(doc.id);
            this.buildings[doc.id]._readyToBuild = false;
          });

          resolve();
        });
    });
  }

  prepare() {
    return new Promise(async (resolve, reject) => {
      if (this.prepared === true) {
        resolve();
        return;
      }

      // --- prepare vending
      this.mintedTokens = [];

      // --- setup Web3 provider
      this.web3 = this.user.web3;

      // @ts-ignore
      await this.web3.currentProvider.enable();

      this.web3.eth.getAccounts(async (err, accounts) => {
        if (err != null) reject();
        else if (accounts.length === 0) reject();
        else {
          // --- create ether provider
          this.etherProvider = new ethers.providers.Web3Provider(
            this.web3.currentProvider as any
          );
          this.signer = this.etherProvider.getSigner();

          // --- prepare contracts
          this.vendingContract = new ethers.Contract(
            bcContracts.contracts.addresses.BlockCitiesVendingMachine(
              this.networkID
            ).address,
            bcContracts.contracts.addresses.BlockCitiesVendingMachine(
              this.networkID
            ).abi,
            this.signer
          );

          // https://rinkeby.etherscan.io/address/0x21bb0accf233eee30a2555ff97b7e8a7535e5fc8#code
          // https://rinkeby.etherscan.io/address/0x60b6e7fc0f009a4aee3ea3781ae20c22cdecb46e#code

          this.limitedVendingContract = new ethers.Contract(
            bcContracts.contracts.addresses.LimitedVendingMachine(
              this.networkID
            ).address,
            bcContracts.contracts.addresses.LimitedVendingMachine(
              this.networkID
            ).abi,
            this.signer
          );

          this.cityBuildingValidator = new ethers.Contract(
            bcContracts.contracts.addresses.CityBuildingValidator(
              this.networkID
            ).address,
            bcContracts.contracts.addresses.CityBuildingValidator(
              this.networkID
            ).abi,
            this.signer
          );

          // --- prepare prices
          const response = await fetch(
            `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`
          );

          const json: any = await response.json();
          if (json) this.ethToUsd = json.ethereum.usd;

          this.prepared = true;

          resolve();
        }
      });
    });
  }

  hashParams(...args: any) {
    return this.web3.utils.soliditySha3(...args);
  }

  async getLimitedBuildingsAllowance() {
    const remaining = this.web3.utils.hexToNumber(
      await this.limitedVendingContract.buildingsMintAllowanceRemaining()
    );

    const total = this.web3.utils.hexToNumber(
      await this.limitedVendingContract.buildingMintLimit()
    );

    return {
      remaining: remaining,
      total: total,
    };
  }

  getPrices(mode: string) {
    return new Promise(async (resolve) => {
      const prices: any = {};
      const contract: ethers.Contract =
        mode === "normal" ? this.vendingContract : this.limitedVendingContract;

      // --- current price
      const currentPriceInWeiNow = await contract.totalPrice(
        mode === "normal" ? 1 : null
      );
      const currentPriceEth = parseFloat(
        this.web3.utils.fromWei(currentPriceInWeiNow.toString())
      );
      prices.currentPrice = currentPriceEth;
      prices.currentPriceStr = currentPriceEth.toFixed(4);
      prices.currentPriceUsdStr = (currentPriceEth * this.ethToUsd).toFixed(2);

      // --- last price
      const lastPriceInWeiNow = await contract.lastSalePrice();
      const lastPriceEth = parseFloat(
        this.web3.utils.fromWei(lastPriceInWeiNow.toString())
      );

      prices.lastPrice = lastPriceEth;
      prices.lastPriceStr = lastPriceEth.toFixed(4);
      prices.lastPriceUsdStr = (lastPriceEth * this.ethToUsd).toFixed(2);

      resolve(prices);
    });
  }

  buildNew(num: number, constructionCallback?: Function) {
    return new Promise(async (resolve) => {
      const currentPriceInWei: any = await this.vendingContract.totalPrice(num);
      const currentPriceInWeiOverpay = currentPriceInWei.add(
        ethers.utils.bigNumberify("300000000000000")
      );

      let overrides = {
        gasLimit: num * 700000,
        value: currentPriceInWeiOverpay,
      };

      // --- START debug
      // const newTokenId = 2125;
      // if (this.mintedTokens.indexOf(newTokenId) === -1)
      //   this.mintedTokens.push(newTokenId);

      // // --- parse and prepare building for user
      // if (newTokenId) {
      //   await this.user.constructBuilding(newTokenId);
      // }

      // resolve();
      // --- END debug

      await this.vendingContract
        .mintBatch(num, overrides)
        .then(async (tx: any) => {
          if (constructionCallback) constructionCallback();

          const receipt = await tx.wait(1);

          const evs = receipt.events.pop();
          const tokenIdBN = evs.args[0];
          const newTokenId = tokenIdBN.toNumber();

          const tokens = [];

          for (let index = 0; index < num; index++) {
            tokens.push(newTokenId - 1 * index);
          }

          for (const token of tokens) {
            if (this.mintedTokens.indexOf(token) === -1) {
              this.mintedTokens.push(token);
            }

            // --- parse and prepare building for user
            if (token && this.networkID === 1) {
              await this.user.constructBuilding(token);
            }
          }

          resolve();
        })
        .catch((error: any) => {
          console.log("error", error);
          resolve();
        });
    });
  }

  buildLimitedNew(params: any, constructionCallback?: Function) {
    return new Promise(async (resolve) => {
      const currentPriceInWei: any = await this.limitedVendingContract.totalPrice();
      const currentPriceInWeiOverpay = currentPriceInWei.add(
        ethers.utils.bigNumberify("300000000000000")
      );

      const overrides: any = {
        value: currentPriceInWeiOverpay,
      };

      overrides.gasLimit = this.networkID === 4 ? 5000000 : 700000;

      this.limitedVendingContract
        .mintBuilding(
          params.building,
          params.base,
          params.body,
          params.roof,
          params.exteriors,
          params.background.id,
          overrides
        )
        .then(async (tx: any) => {
          if (constructionCallback) constructionCallback();

          const receipt = await tx.wait(1);

          const evs = receipt.events.pop();
          const tokenIdBN = evs.args[0];
          const newTokenId = tokenIdBN.toNumber();

          const tokens = [];
          const num = 1;

          for (let index = 0; index < num; index++) {
            tokens.push(newTokenId - 1 * index);
          }

          for (const token of tokens) {
            if (this.mintedTokens.indexOf(token) === -1) {
              this.mintedTokens.push(token);
            }

            // --- parse and prepare building for user
            if (token && this.networkID === 1) {
              await this.user.constructBuilding(token);
            }
          }

          resolve(tokens[0]);
        })
        .catch((error: any) => {
          console.log("error", error);
          resolve();
        });
    });
  }

  clearMintedToken(id: number) {
    const index = this.mintedTokens.indexOf(id);
    if (index > -1) {
      this.mintedTokens.splice(index, 1);
    }
  }
}

export default BuildMachine;
