import React, { useState, useContext, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
} from "@ionic/react";
import { RouteProps } from "react-router";
import "./MysteryMachine.css";

import User, { UserContext } from "../../components/user";
import { EPlaycanvasEvents } from "../../components/playcanvas";

export interface MysteryMachineProps extends RouteProps {
  setStateModal: Function;
  useModal: boolean;
}

const MysteryMachine: React.FC<MysteryMachineProps> = (
  props: MysteryMachineProps
) => {
  const user: User = useContext(UserContext);

  const [prices, setPrices] = useState({
    currentPrice: 0,
    lastPrice: 0,
    currentPriceStr: "",
    lastPriceStr: "",
    currentPriceUsdStr: "",
    lastPriceUsdStr: "",
  });

  const [buildingsNo, setBuildingsNo] = useState(1);

  useEffect(() => {
    user.buildMachine
      .prepare()
      .then(() => {
        user.buildMachine.getPrices("normal").then((prices: any) => {
          setPrices(prices);
        });
      })
      .catch(() => {
        if (props.useModal === true) props.setStateModal(false);
      });
  }, []);

  const mainComponent: any = (
    <div className="enable-pointer-events build-page">
      <div className="build-content">
        <div className="build-page-div">
          {props.useModal === true ? (
            <img
              className="tab-icon"
              src="https://uploads-ssl.webflow.com/5d391fec3211a2242af4f911/5e0ad539edb7542d0caedf8d_Vector%206-1.svg"
              onClick={() => {
                if (props.useModal === true) props.setStateModal(false);
              }}
            />
          ) : (
            <div></div>
          )}

          <p className="page-header">Mystery Machine</p>

          <div className="building-mystery-group">
            <img
              className="building-mystery"
              src={require("../../images/building-gif.gif")}
            ></img>
          </div>

          <IonGrid>
            <IonRow>
              <IonCol className="no-padding" size="7">
                <div className="build-wrap left grid">
                  <div>
                    <p className="building-price">
                      ${prices.currentPriceUsdStr}
                    </p>
                    <p className="building-price building-price-small">
                      ETH{prices.currentPriceStr}
                    </p>
                    <p className="build-title">Current Price</p>
                  </div>

                  <div>
                    <p className="building-price">${prices.lastPriceUsdStr}</p>
                    <p className="building-price building-price-small">
                      ETH{prices.lastPriceStr}
                    </p>
                    <p className="build-title">Last Price</p>
                  </div>
                </div>
              </IonCol>

              <IonCol className="no-padding" size="5">
                <div className="build-wrap right">
                  <div className="grid-3">
                    <IonButton
                      className={`number-button ${
                        buildingsNo === 1 ? "blue" : ""
                      }`}
                      size="small"
                      onClick={() => {
                        setBuildingsNo(1);
                      }}
                    >
                      1
                    </IonButton>
                    <IonButton
                      className={`number-button ${
                        buildingsNo === 2 ? "blue" : ""
                      }`}
                      size="small"
                      onClick={() => {
                        setBuildingsNo(2);
                      }}
                    >
                      2
                    </IonButton>
                    <IonButton
                      className={`number-button ${
                        buildingsNo === 3 ? "blue" : ""
                      }`}
                      size="small"
                      onClick={() => {
                        setBuildingsNo(3);
                      }}
                    >
                      3
                    </IonButton>
                  </div>
                  <p className="build-title">Quantity</p>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="build-button-outline">
            <div
              className="build-button"
              onClick={() => {
                user.buildMachine.buildNew(buildingsNo, () => {
                  if (props.useModal === true) props.setStateModal(false);

                  user.playcanvas.sendMessage(
                    EPlaycanvasEvents.Buildings_Constructing
                  );
                });
              }}
            >
              Build Mystery Building
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return props.useModal === true ? (
    mainComponent
  ) : (
    <IonPage>
      <IonContent>{mainComponent}</IonContent>
    </IonPage>
  );
};

export default MysteryMachine;
