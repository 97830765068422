import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from "@ionic/react";
import React from "react";

import "./AboutPage.css";

const About: React.FC = (props: any) => {
  return (
    <IonPage>
      <IonContent className="">
        <div className="max-width four-fifty">
          <IonHeader className="header four-fifty">
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="2">
                  <div></div>
                </IonCol>

                <IonCol size="8">
                  <h3 className="page-header">About</h3>
                </IonCol>

                <IonCol size="2" className="no-padding">
                  <IonButton
                    className="back-button no-padding"
                    onClick={() => props.history.goBack()}
                  >
                    <img
                      className="back-arrow"
                      src={require("../images/x-icon.svg")}
                    ></img>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>

          <div className="about-page">
            <div className="about-group">
              <div className="about-head-flex">
                <img
                  className="about-logo"
                  src={require("../images/logo-blue-circle.png")}
                ></img>
              </div>
              <div className="about-heading">BlockCities</div>
              <div className="header-image"></div>
              <div className="about-text">
                BlockCities is city building game. Collect rare buildings, trade
                with friends, and assemble tiny cities.What's the point? To have
                fun, to build, to be the architect and city planner inside us
                all.{" "}
              </div>
            </div>
            <div className="about-line"></div>
            <div className="about-group">
              <div className="about-heading">How to Play</div>
              <div className="about-sub-heading">Add Buildings</div>
              <div className="about-text">
                Click the blue button and select a building to add to your city.{" "}
              </div>
              <div className="about-sub-heading">Move Buildings</div>
              <div className="about-text">
                Click a building on your city to select. Once selected, drag
                elsewhere to move.{" "}
              </div>
              <div className="about-sub-heading">Add Road</div>
              <div className="about-text">
                Click one of the road buttons: Asphalt, Quartz, or Concrete Once
                selected, select a grid square to add a road segment. Zoom is
                disabled when creating roads.
              </div>
            </div>
            <div className="about-line"></div>
            <div className="about-group">
              <div className="about-heading">Upcoming Features</div>
              <div className="feature">
                <div className="emoji-circle blue">
                  <img
                    className="emoji"
                    src={require("../images/about/office-building_1f3e2.png")}
                  ></img>
                </div>
                <div className="feature-text-2">Add your own buildings</div>
              </div>
              <div className="feature">
                <div className="emoji-circle yellow">
                  <img
                    className="emoji"
                    src={require("../images/about/construction-sign_1f6a7.png")}
                  ></img>
                </div>
                <div className="feature-text-2">
                  More road types &amp; waterways
                </div>
              </div>
              <div className="feature">
                <div className="emoji-circle green">
                  <img
                    className="emoji"
                    src={require("../images/about/deciduous-tree_1f333.png")}
                  ></img>
                </div>
                <div className="feature-text-2">Add trees &amp; parks</div>
              </div>
              <div className="feature">
                <div className="emoji-circle yellow">
                  <img
                    className="emoji"
                    src={require("../images/about/desert-island_1f3dd.png")}
                  ></img>
                </div>
                <div className="feature-text-2">
                  Construct and destruct your island
                </div>
              </div>
              <div className="feature">
                <div className="emoji-circle blue">
                  <img
                    className="emoji"
                    src={require("../images/about/eyes_1f440.png")}
                  ></img>
                </div>
                <div className="feature-text-2">Follow other cities</div>
              </div>
            </div>
            <div className="about-share">
              <div className="about-heading center">let 'em know</div>
              <a
                href="https://twitter.com/intent/tweet?text=I+just+built+a+tiny+city+on+BlockCities+%F0%9F%99%88&amp;via=blockcities"
                target="_blank"
                className="header-button small w-inline-block"
              >
                <img
                  className="twitter-icon"
                  height="16"
                  src={require("../images/social/twitter-logo.svg")}
                ></img>

                <div className="nav-text large white">Tweet</div>
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
