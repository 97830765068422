import { IonRow, IonCol } from "@ionic/react";
import React, { useContext, useState } from "react";
import { RouteProps } from "react-router";

import * as routes from "../constants/routes";
import "./City.css";

import BuildingProfile from "./BuildingProfile";

import User, { UserContext } from "../components/user";
import { EPlaycanvasEvents } from "../components/playcanvas";

export interface SelectBuildingButtonsProps extends RouteProps {
  buildingSelected: any;
  showRemove: boolean;
}

const SelectBuildingButtons: React.FC<SelectBuildingButtonsProps> = (
  props: SelectBuildingButtonsProps
) => {
  const user: User = useContext(UserContext);

  const building: any = user.getUserToken(props.buildingSelected.id);

  const [buildingProfileOpen, setBuildingProfileOpen] = useState(false);
  const [buildingProfileID, setBuildingProfileID] = useState(null);

  return (
    <IonRow className="no-padding">
      <IonCol
        className="no-padding"
        size={props.showRemove === true ? "10" : "12"}
      >
        <BuildingProfile
          open={buildingProfileOpen}
          buildingID={buildingProfileID}
          setOpenState={setBuildingProfileOpen}
          useModal={true}
        />
        <div className="info-flex">
          <div className="top-nav-info enable-pointer-events">
            <p className="button-text">Building #{building.id}</p>
          </div>

          <div
            className="top-nav-outline text view"
            onClick={() => {
              setBuildingProfileOpen(true);
              setBuildingProfileID(building.id);
            }}
          >
            <div className="top-nav-button text enable-pointer-events">
              <p className="button-text">View</p>
            </div>
          </div>
        </div>
      </IonCol>

      <IonCol size="2" className="no-padding">
        {props.showRemove === true ? (
          <div className="top-nav-outline right">
            <div
              className="top-nav-button red enable-pointer-events"
              onClick={() => {
                user.playcanvas.sendMessage(
                  EPlaycanvasEvents.City_RemoveSelectedBuilding
                );
              }}
            >
              <img
                className="nav-icon"
                src={require("../images/remove-icon.svg")}
              ></img>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </IonCol>
    </IonRow>
  );
};

export default SelectBuildingButtons;
