import React, { useState, useContext, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSlides,
  IonSlide,
  IonInput,
  IonLoading
} from "@ionic/react";
import "./Start.css";

import User, { UserContext } from "../components/user";

import * as routes from "../constants/routes";

const SignUp: React.FC = (props: any) => {
  const user: User = useContext(UserContext);

  const [loading, SetLoading] = useState(false);

  const [betaKey, setBetaKey] = useState("");

  const [fullname, setFullname] = useState(null);
  const [username, setUsername] = useState(null);

  let slidesRef: any = React.createRef<any>();
  const slideOpts = {
    initialSlide: props.location.pathname === routes.SIGNIN ? 2 : 0,
    speed: 400,
    allowTouchMove: false
  };

  const goToCity = async () => {
    SetLoading(true);

    await user.prepareUser();

    SetLoading(false);

    props.history.replace(routes.CITY, "none");
  };

  let checkingBetaKey = false;

  const executeCheckBetaKey = async () => {
    if (checkingBetaKey === true) return;
    checkingBetaKey = true;

    const valid = await user.checkBetaKey(betaKey);

    if (valid) {
      slidesRef.current.slideNext();
    } else {
      alert("A valid beta key is required.");
    }

    checkingBetaKey = false;
  };

  const executeSignIn = async (type: string, steps: number) => {
    for (let i = 0; i < steps; i++) {
      await slidesRef.current.slideNext();
    }

    user
      .doSignIn(type)
      .then(async existing => {
        if (existing) {
          goToCity();
        } else if (await user.checkBetaKey(betaKey)) {
          slidesRef.current.slideNext();
        } else {
          alert("A valid beta key is required.");
          window.location.reload();
          return;
        }
      })
      .catch(() => {
        if (props.location.pathname === routes.SIGNIN) {
          props.history.goBack();
        } else {
          for (let i = 0; i < steps; i++) {
            slidesRef.current.slidePrev();
          }
        }
      });
  };

  const updateWalletProvider = async (provider: string) => {
    user.current.private.provider = provider;

    await slidesRef.current.slideNext();
  };

  let processSignUp = false;

  const signUp = async () => {
    if (processSignUp === true) return false;

    processSignUp = true;

    // --- check if username is unique
    const usernameValid = await user.checkUsernameExists(username);

    if (usernameValid !== null) {
      processSignUp = false;
      alert("This username is already taken.");
      return;
    }

    user.current.fullname = fullname;
    user.current.username = username;

    await user.doSignUp(betaKey);

    goToCity();
  };

  // initial state
  useEffect(() => {
    if (props.location.pathname === routes.SIGNIN) {
      executeSignIn("email", 0);
    }
  }, []);

  return loading === true ? (
    <div>
      <IonLoading isOpen={true} message={"Building..."} duration={0} />
    </div>
  ) : (
    <IonPage>
      <IonHeader className="header"></IonHeader>
      <IonContent>
        <IonSlides ref={slidesRef} pager={false} options={slideOpts}>
          <IonSlide>
            <div className="slide">
              <p className="start-header-text">Beta Code</p>
              <p className="start-body-text">Already have an account?</p>
              <p
                className="start-body-text black"
                onClick={async () => executeSignIn("email", 2)}
              >
                Sign In
              </p>

              <div className="input-group">
                <IonInput
                  className="signup-input center"
                  placeholder="0000"
                  maxlength={4}
                  onIonChange={e => {
                    setBetaKey(e.detail.value);
                  }}
                ></IonInput>
              </div>

              <p className="start-body-text">
                BlockCities is currently in closed beta. Please sign up on our
                waitlist to recieve a code.
              </p>

              <div
                className="right-arrow-div"
                onClick={() => {
                  if (betaKey && betaKey.length === 4) {
                    executeCheckBetaKey();
                  } else {
                    alert("A valid beta key is required.");
                  }
                }}
              >
                <img
                  className="right-arrow"
                  src={require("../images/right-arrow.svg")}
                ></img>
              </div>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="slide">
              <p className="start-header-text">Create Account</p>
              <p className="start-body-text">Already have an account?</p>
              <p
                className="start-body-text black"
                onClick={async () => executeSignIn("email", 1)}
              >
                Sign In
              </p>

              <div
                className="choose-wallet-button"
                onClick={async () => executeSignIn("phone", 1)}
              >
                <div className="wallet-logo-div 1">
                  <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/phone.png")}
                  ></img>
                </div>
                <p className="no-padding">Use Phone</p>
              </div>

              <div
                className="choose-wallet-button grey"
                onClick={async () => executeSignIn("email", 1)}
              >
                <div className="wallet-logo-div 1">
                  <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/email.png")}
                  ></img>
                </div>
                <p className="no-padding">Use Email</p>
              </div>

              {/* <div className="existing-wallet-button">
                  <p className="existing-wallet-text">or create account with</p>
                  <p className="existing-wallet-text black">existing wallet</p>
                </div>

                <p className="start-body-text">or</p>

                <div className="choose-wallet-button grey">
                  <div className="wallet-logo-div">
                    <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/coinbase-wallet.png")}
                    ></img>
                  </div>
                  <p className="no-padding">Coinbase Wallet</p>
                </div>

                <div className="choose-wallet-button grey">
                  <div className="wallet-logo-div">
                    <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/metamask.png")}
                    ></img>
                  </div>
                  <p className="no-padding">Metamask Mobile</p>
                </div>

                <div className="choose-wallet-button grey">
                  <div className="wallet-logo-div">
                    <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/fortmatic.png")}
                    ></img>
                  </div>
                  <p className="no-padding">Fortmatic</p>
                </div> */}
            </div>
          </IonSlide>

          <IonSlide>
            {/* <div className="slide">
              <p className="start-header-text">Create Account</p>
              <p className="start-body-text">Already have an account?</p>
              <p className="start-body-text black">Sign In</p>
            </div> */}
          </IonSlide>

          <IonSlide>
            <div className="slide">
              <p className="start-header-text">
                Do you have an existing wallet?
              </p>
              <p className="start-body-text">
                Every account needs a wallet. If you don't have one, we'll
                create one for you.
              </p>

              <div
                className="choose-wallet-button"
                onClick={() => updateWalletProvider("fortmatic")}
              >
                <div className="wallet-logo-div">
                  <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/phone.png")}
                  ></img>
                </div>
                <p className="no-padding">Create New Wallet</p>
              </div>

              <div className="choose-wallet-button grey">
                <div className="wallet-logo-div">
                  <img
                    className="wallet-logo dropshadow"
                    src={require("../images/wallet-logos/metamask.png")}
                  ></img>
                </div>
                <p className="no-padding">Connect Existing Wallet</p>
              </div>

              <p className="start-body-text">
                If you already have buildings, connect that wallet here.
              </p>

              <div
                className="choose-wallet-button grey"
                onClick={() => updateWalletProvider("ethereum")}
              >
                <div className="wallet-logo-div">
                  <img
                    className="wallet-logo"
                    src={require("../images/wallet-logos/coinbase-wallet.png")}
                  ></img>
                </div>
                <p className="no-padding">Coinbase Wallet</p>
              </div>

              <div
                className="choose-wallet-button grey"
                onClick={() => updateWalletProvider("ethereum")}
              >
                <div className="wallet-logo-div">
                  <img
                    className="wallet-logo dropshadow"
                    src={require("../images/wallet-logos/metamask.png")}
                  ></img>
                </div>
                <p className="no-padding">Metamask Mobile</p>
              </div>

              <div
                className="choose-wallet-button grey"
                onClick={() => updateWalletProvider("ethereum")}
              >
                <div className="wallet-logo-div">
                  <img
                    className="wallet-logo dropshadow"
                    src={require("../images/wallet-logos/ethereum-logo.png")}
                  ></img>
                </div>
                <p className="no-padding">Other Mobile Wallet</p>
              </div>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="slide">
              <p className="start-header-text">What's your name?</p>

              <div className="input-group">
                <IonInput
                  className="signup-input center"
                  placeholder="Your Name"
                  maxlength={30}
                  onIonChange={e => {
                    setFullname(e.detail.value);
                  }}
                ></IonInput>
              </div>

              <p className="start-body-text">
                Use a nickname. your real name, whatever.
              </p>

              <div
                className="right-arrow-div"
                onClick={() => {
                  if (fullname && fullname.length > 0) {
                    slidesRef.current.slideNext();
                  } else {
                    alert("A name is required.");
                  }
                }}
              >
                <img
                  className="right-arrow"
                  src={require("../images/right-arrow.svg")}
                ></img>
              </div>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="slide">
              <p className="start-header-text">Choose a Username</p>

              <div className="input-group">
                <div className="signup-input-div">
                  <p className="signup-input black">@</p>
                </div>

                <div className="signup-input-div">
                  <IonInput
                    className="signup-input"
                    placeholder="username"
                    maxlength={15}
                    onIonChange={e => {
                      setUsername(e.detail.value);
                    }}
                  ></IonInput>
                </div>
              </div>

              <p className="start-body-text">
                This is unique to you. So make it special 🎉
              </p>

              <div
                className="right-arrow-div"
                onClick={() => {
                  if (username && username.length > 0) {
                    signUp();
                  } else {
                    alert("A username is required.");
                  }
                }}
              >
                <img
                  className="right-arrow"
                  src={require("../images/right-arrow.svg")}
                ></img>
              </div>
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
