export const LANDING = "/";
export const CITY = "/city";
export const CITY_VIEW = "/city/:nickname";
export const USER = "/user";
export const USER_VIEW = "/user/:nickname";
export const BUILDING_VIEW = "/building/:buildingID";
export const WALLET = "/wallet";
export const SETTINGS = "/settings";
export const START = "/start";
export const SIGNIN = "/sign-in";
export const SIGNUP = "/sign-up";
export const ABOUTPAGE = "/about";

export const MYSTERY_MACHINE = "/mystery-machine";
export const BROOKLYN_KIOSK = "/brooklyn-kiosk";
