import React, { useState, useContext } from "react";
import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonCol,
  IonRow,
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import "./Settings.css";

import User, { UserContext } from "../components/user";

import * as routes from "../constants/routes";

const BuildBuilding: React.FC = () => {
  const user: User = useContext(UserContext);

  return (
    <IonPage>
      <IonContent>
        <div className="max-width">
          <IonHeader className="header">
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="3" className="no-padding">
                  <IonRouterLink
                    routerLink={routes.USER}
                    routerDirection="back"
                  >
                    <IonButton className="back-button no-padding">
                      <img
                        className="back-arrow"
                        src={require("../images/x-icon.svg")}
                      ></img>
                    </IonButton>
                  </IonRouterLink>
                </IonCol>

                <IonCol size="6">
                  <h3 className="page-header">Settings</h3>
                </IonCol>

                {/* <IonCol size="3" className="no-padding">
                  <IonButton fill="outline" className="wallet-button">
                    Save
                  </IonButton>
                </IonCol> */}
              </IonRow>
            </IonGrid>
          </IonHeader>

          <div className="settings-group">
            <p className="settings-label">USERNAME</p>
            <p className="settings-input grey">@</p>
            <p className="settings-input">{user.current.username}</p>

            <p className="settings-label">DISPLAY NAME</p>
            <p className="settings-input">{user.current.fullname}</p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BuildBuilding;
