import React, { useContext } from "react";
import { IonRow, IonCol } from "@ionic/react";
import "./City.css";

import User, { UserContext } from "../components/user";
import { EPlaycanvasEvents } from "../components/playcanvas";
import { RouteProps } from "react-router";

export interface RoadModeButtonsProps extends RouteProps {
  roadType: any;
}

const RoadModeButtons: React.FC<RoadModeButtonsProps> = (
  props: RoadModeButtonsProps
) => {
  const user: User = useContext(UserContext);

  return (
    <IonRow className="no-padding">
      <IonCol className="no-padding" size="10">
        <div className="info-flex">
          <div className="top-nav-info">
            <p className="button-text">Add {props.roadType}</p>
          </div>
        </div>
      </IonCol>

      <IonCol size="2" className="no-padding">
        <div
          className="top-nav-outline right enable-pointer-events"
          onClick={() => {
            user.playcanvas.sendMessage(EPlaycanvasEvents.City_AddRoad, {
              state: false
            });
          }}
        >
          <div className="top-nav-button green">
            <img
              className="nav-icon"
              src={require("../images/check-mark.svg")}
            ></img>
          </div>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default RoadModeButtons;
