import React, { useState, useContext } from "react";
import { useParams } from "react-router";

import {
  IonPage,
  IonContent,
  IonCol,
  IonRow,
  IonGrid,
  IonModal,
} from "@ionic/react";
import { RouteProps } from "react-router";
import "./BuildingProfile.css";
import PublicNavButtons from "./PublicNavButtons";

import { Swipeable } from "react-swipeable";

import User, { UserContext } from "../components/user";
import * as icons from "../constants/icons";

export interface BuildProfileProps extends RouteProps {
  open: boolean;
  setOpenState: Function;
  buildingID: any;
  useModal: boolean;
}

const BuildingProfile: React.FC<BuildProfileProps> = (props) => {
  const user: User = useContext(UserContext);

  let { buildingID } = useParams();

  if (props.buildingID) {
    buildingID = props.buildingID;
  }

  const [stateModal, setStateModal] = useState(true);

  const building: any = user.getUserToken(buildingID);
  const badges: any[] = icons.getBuildingBadges(building);

  // useEffect(() => {
  // }, []);

  const mainComponent: any = !building ? (
    <div></div>
  ) : (
    <div>
      {props.useModal === true ? (
        <Swipeable
          onSwipedDown={() => props.setOpenState(false)}
          trackMouse={true}
        >
          <img
            className="tab-icon"
            src="https://uploads-ssl.webflow.com/5d391fec3211a2242af4f911/5e0ad539edb7542d0caedf8d_Vector%206-1.svg"
            onClick={() => {
              props.setOpenState(false);
            }}
          />
        </Swipeable>
      ) : (
        <div className="public-nav-buttons">
          <PublicNavButtons
            currentPath={props.location}
            setStateModal={setStateModal}
          />
        </div>
      )}
      <div
        className="building-group-profile"
        style={{
          backgroundColor: "#" + building.background_color,
        }}
      >
        {badges.map((badgeUrl) => {
          return <img className="badge" src={badgeUrl} />;
        })}
        <img className="building-image-profile" src={building.imageStore} />
      </div>
      <h1 className="building-name-profile">{building.attributes.building}</h1>
      <IonGrid className="remove-padding">
        <IonRow>
          <IonCol size="6" className="ion-align-self-start">
            <div className="text-wrap">
              <p className="body-text">Building {building.description}</p>
            </div>
          </IonCol>

          <IonCol size="3.2" className="">
            <div className="text-wrap middle">
              <p className="body-text">{building.cityShort}</p>
            </div>
          </IonCol>

          <IonCol className="ion-align-self-end">
            <div className="text-wrap">
              <p className="body-text">ERA {building.era}</p>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* <IonGrid className="owner-group-profile">
        <IonRow>
          <IonCol size="9">
            <div>
              <p className="owner-name">{user.current.fullname}</p>
              <p className="owner-address">{building.ownerShort}</p>
            </div>
          </IonCol>

          <IonCol size="3">
            <IonButton
              className="follow-button"
              shape="round"
              fill="outline"
              color="dark"
              size="small"
            >
              Follow
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid> */}
      <div className="details-wrap">
        <h1 className="subheading">Details</h1>

        <IonGrid>
          <IonRow>
            <IonCol size="6" className="no-padding">
              <p className="body-text light left">Built</p>
            </IonCol>

            <IonCol size="6" className="no-padding">
              <p className="body-text right">{building.blockTimestampPretty}</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6" className="no-padding">
              <p className="body-text light left">City</p>
            </IonCol>

            <IonCol size="6" className="no-padding">
              <p className="body-text right">{building.attributes.city}</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6" className="no-padding">
              <p className="body-text light left">Height</p>
            </IonCol>

            <IonCol size="6" className="no-padding">
              <p className="body-text right">{building.attributes.height} ft</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6" className="no-padding">
              <p className="body-text light left">Height Class</p>
            </IonCol>

            <IonCol size="6" className="no-padding">
              <p className="body-text right">
                {building.attributes.heightClass}
              </p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6" className="no-padding">
              <p className="body-text light left">Era Class</p>
            </IonCol>

            <IonCol size="6" className="no-padding">
              <p className="body-text right">{building.eraClass}</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      {building.special === 0 ? (
        <div className="details-wrap">
          <h1 className="subheading">Rooftop</h1>
          <p className="subheading-small">{building.attributes.rooftopType}</p>

          <IonGrid>
            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.exteriorColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.exteriorColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Exterior Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.roofWindowColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.roofWindowColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectWindowIcon(building.attributes.windowType)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.windowType}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Type</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectUseIcon(building.attributes.rooftopUse)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.rooftopUse}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Use</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : (
        <div></div>
      )}
      {building.special === 0 ? (
        <div className="details-wrap">
          <h1 className="subheading">Core</h1>
          <p className="subheading-small">{building.attributes.coreType}</p>

          <IonGrid>
            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.exteriorColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.exteriorColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Exterior Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.bodyWindowColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.bodyWindowColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectWindowIcon(building.attributes.coreType)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">{building.attributes.coreType}</p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Type</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectUseIcon(building.attributes.coreUse)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">{building.attributes.coreUse}</p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Use</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : (
        <div></div>
      )}
      {building.special === 0 ? (
        <div className="details-wrap">
          <h1 className="subheading">Ground Floor</h1>
          <p className="subheading-small">
            {building.attributes.groundFloorType}
          </p>

          <IonGrid>
            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.exteriorColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.exteriorColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Exterior Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectColorIcon(
                    building.attributes.baseWindowColorway
                  )}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.baseWindowColorway}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Color</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectWindowIcon(building.attributes.windowType)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.windowType}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Window Type</p>
              </IonCol>
            </IonRow>

            <IonRow className="sm-padding">
              <IonCol size="1.75" className="no-padding">
                <img
                  className="building-attribute-img"
                  src={icons.selectUseIcon(building.attributes.groundFloorUse)}
                />
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text left">
                  {building.attributes.groundFloorUse}
                </p>
              </IonCol>

              <IonCol size="5" className="no-padding">
                <p className="body-text small">Use</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : (
        <div></div>
      )}
      {/* <div className="details-wrap">
  <h1 className="subheading">Building Bio</h1>
  <p className="building-bio-text">
    American Copper Buildings (originally known as 626 First Avenue) is
    a dual-tower residential skyscraper in the Murray Hill neighborhood
    of Manhattan in New York City (US). The building was developed by
    JDS Development and Largo and was designed by SHoP Architects. The
    building is one of several major collaborations between JDS and
    SHoP; others include 111 West 57th Street, also in Manhattan, and 9
    DeKalb Avenue, in Brooklyn.
  </p>
  <p className="source-text">Source: Wikipedia</p>
</div> */}
    </div>
  );

  return props.useModal === true ? (
    <IonModal
      id="modal-building-profile"
      backdropDismiss={true}
      isOpen={props.open}
      onDidDismiss={() => props.setOpenState(false)}
    >
      <div className="enable-pointer-events building-modal hide-scrollbar">
        {mainComponent}
      </div>
    </IonModal>
  ) : (
    <IonPage>
      <IonContent className="custom-background">
        <IonModal
          id="modal-building-profile"
          isOpen={stateModal}
          showBackdrop={false}
          backdropDismiss={false}
        >
          <div className="enable-pointer-events building-modal hide-scrollbar">
            {mainComponent}
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default BuildingProfile;
