import React, { useState, useContext, useEffect } from "react";
import { IonContent, IonModal } from "@ionic/react";
import "./BottomSheet.css";

import { Swipeable } from "react-swipeable";

import MysteryMachine from "./machines/MysteryMachine";
import BrooklynKiosk from "./machines/BrooklynKiosk";

import User, { UserContext } from "../components/user";
import Playcanvas, {
  PlaycanvasContext,
  EPlaycanvasEvents,
} from "../components/playcanvas";

const BottomSheet: React.FC = () => {
  const user: User = useContext(UserContext);
  const playcanvas: Playcanvas = useContext(PlaycanvasContext);

  const [state, setState] = useState("mid");

  const [showBuildModal, setShowBuildModal] = useState(false);
  const [showBrooklynKioskModal, setShowBrooklynKioskModal] = useState(false);

  const [buildings, setBuildings] = useState(user.tokens ? user.tokens : []);

  useEffect(() => {
    playcanvas.on(EPlaycanvasEvents.Building_Ready, (id: number) => {
      setBuildings([...user.tokens]);
    });

    playcanvas.on(EPlaycanvasEvents.Building_Unveil, (id: number) => {
      setBuildings([...user.tokens]);
    });

    playcanvas.on(EPlaycanvasEvents.Building_Available, (id: number) => {
      const building: any = buildings.find((o: any) => o.id === id);
      if (building) building._availableToBuild = true;
    });

    playcanvas.on(EPlaycanvasEvents.Building_Unavailable, (id: number) => {
      const building: any = buildings.find((o: any) => o.id === id);
      if (building) building._availableToBuild = false;
    });
  }, []);

  const nextState = (cycle?: boolean) => {
    if (state === "closed") {
      setState("mid");
    } else if (state === "mid") {
      setState("full");
    } else if (state === "full" && cycle === true) {
      setState("closed");
    }
  };

  const prevState = (cycle?: boolean) => {
    if (state === "full") {
      setState("mid");
    } else if (state === "mid") {
      setState("closed");
    } else if (state === "closed" && cycle === true) {
      setState("full");
    }
  };

  const handleBottomSheetState = () => {
    nextState(true);
  };

  const toggleRoadBuilding = (roadType: string, displayName: string) => {
    playcanvas.sendMessage(EPlaycanvasEvents.City_AddRoad, {
      state: true,
      type: roadType,
      displayName: displayName,
    });

    setState("mid");
  };

  return (
    <IonContent className={`enable-pointer-events bottom-sheet ${state}`}>
      <IonModal
        id="modal-build-building"
        backdropDismiss={true}
        isOpen={showBuildModal}
        onDidDismiss={() => setShowBuildModal(false)}
      >
        <Swipeable
          onSwipedDown={() => setShowBuildModal(false)}
          trackMouse={true}
        >
          <MysteryMachine setStateModal={setShowBuildModal} useModal={true} />
        </Swipeable>
      </IonModal>
      <IonModal
        id="modal-build-brooklyn-kiosk"
        backdropDismiss={true}
        isOpen={showBrooklynKioskModal}
        onDidDismiss={() => setShowBrooklynKioskModal(false)}
      >
        <Swipeable
          onSwipedDown={() => setShowBrooklynKioskModal(false)}
          trackMouse={true}
        >
          <BrooklynKiosk
            setStateModal={setShowBrooklynKioskModal}
            useModal={true}
          />
        </Swipeable>
      </IonModal>

      <Swipeable
        onSwipedDown={() => prevState(false)}
        onSwipedUp={() => nextState(false)}
        trackMouse={true}
      >
        <div>
          <img
            onClick={handleBottomSheetState}
            className="tab-icon"
            src="https://uploads-ssl.webflow.com/5d391fec3211a2242af4f911/5e0ad539edb7542d0caedf8d_Vector%206-1.svg"
          />
        </div>

        <p className="bottom-sheet-label">Roads, Waterways & Trees</p>

        <div className="resource-group hide-scrollbar">
          <div
            className="resource-button asphalt"
            onClick={() => toggleRoadBuilding("asphalt", "Asphalt Road")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/Asphalt-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Asphalt</p> */}
          </div>

          <div
            className="resource-button quartz"
            onClick={() => toggleRoadBuilding("quartz", "Quartz Road")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/Quartz-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Quartz</p> */}
          </div>

          <div
            className="resource-button concrete"
            onClick={() => toggleRoadBuilding("stone", "Concrete Road")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/Concrete-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Concrete</p> */}
          </div>

          <div
            className="resource-button water"
            onClick={() => toggleRoadBuilding("water", "Water")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/Water-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Water</p> */}
          </div>

          <div
            className="resource-button evergreen"
            onClick={() => toggleRoadBuilding("evergreen", "Evergreen")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/evergreen-tree-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Evergreen</p> */}
          </div>

          <div
            className="resource-button blossom"
            onClick={() => toggleRoadBuilding("cherry", "Cherry Blossom")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/cherry-blossom-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Blossom Tree</p> */}
          </div>

          <div
            className="resource-button deciduous"
            onClick={() => toggleRoadBuilding("deciduous", "Deciduous")}
          >
            <div className="resource-image">
              <img
                className="resource-icon"
                src={require("../images/resource-icons/deciduous-tree-icon.png")}
              ></img>
            </div>
            {/* <p className="resource-text">Deciduous</p> */}
          </div>
        </div>

        <p className="bottom-sheet-label">Buildings</p>

        <div className="resource-group hide-scrollbar">
          <div
            className="resource-button rainbow"
            onClick={() => {
              setShowBuildModal(true);
            }}
          >
            <img
              className="build-building-icon"
              src={require("../images/pink-modern.png")}
            ></img>
            <p className="resource-text build-building-text">
              Build Mystery Building
            </p>
          </div>
          <div
            className="resource-button brooklyn"
            onClick={() => {
              setShowBrooklynKioskModal(true);
            }}
          >
            <img
              className="build-building-icon"
              src={require("../images/brooklyn-icon.png")}
            ></img>
            <p className="resource-text build-building-text">
              Brooklyn Building
            </p>
          </div>
        </div>
      </Swipeable>

      <div className="building-row hide-scrollbar">
        <div className="inner">
          {buildings.map((building: any) => {
            if (
              building._readyToBuild === false ||
              building._availableToBuild === false
            )
              return undefined;
            return (
              <div
                key={building.id}
                className="building-column"
                color="light"
                onClick={() => {
                  playcanvas.sendMessage(
                    EPlaycanvasEvents.City_AddBuilding,
                    building.id
                  );

                  setState("closed");
                }}
              >
                <div className="building-group">
                  <p className="building-name">
                    {building.attributes.building}
                  </p>

                  <img className="building-image" src={building.imageStore} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </IonContent>
  );
};

export default BottomSheet;
