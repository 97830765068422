import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

import { IUserData } from "../user/user";

const config = {
  apiKey: "AIzaSyASy6HA6rESDpGGzuhr5yRD8v9w4739izY",
  authDomain: "block-cities.firebaseapp.com",
  databaseURL: "https://block-cities.firebaseio.com",
  projectId: "block-cities",
  storageBucket: "block-cities.appspot.com",
  messagingSenderId: "34610683078",
  appId: "1:34610683078:web:6020b6eeb997416dea8bc7",
};

class Firebase {
  public auth: firebase.auth.Auth;
  public database: firebase.database.Database;
  public firestore: firebase.firestore.Firestore;

  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.database = firebase.database();
    this.firestore = firebase.firestore();
  }

  public async getPlaycanvasAppUrl() {
    const snapshot = await this.database
      .ref("settings/playcanvasUrl")
      .once("value");
    return snapshot.val();
  }

  private getFirebaseEmailFromUserID = (userID: string) => {
    // .replace(/[^0-9a-z]/gi, "")
    return userID.toLowerCase() + "@api.blockcities.co";
  };

  doFirebaseAuth = (user: IUserData) => {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged((authUser) => {
        user.private.firebaseEmail = this.getFirebaseEmailFromUserID(
          user.private.fortmaticID
        );

        if (authUser) {
          // check if we got the right user signed in, if yes fill his user object info
          if (authUser.email === user.private.firebaseEmail) {
            this.database
              .ref("users/" + authUser.uid)
              .once("value", (snapshot: any) => {
                var userData = snapshot.val();

                if (userData) {
                  user.private.firebaseID = userData.private.firebaseID;
                  if (userData.private.email)
                    user.private.email = userData.private.email;
                  if (userData.private.phone)
                    user.private.phone = userData.private.phone;

                  user.publicAddress = userData.publicAddress;
                  user.private.provider = userData.private.provider;
                  user.fullname = userData.fullname;
                  user.username = userData.username;
                } else {
                  user.private.firebaseID = authUser.uid;

                  this.database
                    .ref("users/" + user.private.firebaseID)
                    .set(user);
                }

                resolve(false);
              });
          } else {
            // if he is not the right user, let's sign out and the process will rerun
            this.auth.signOut();
          }
        } else {
          // --- we try to create an account for the user, if he exists we catch the error to sign him in automatically
          this.auth
            .createUserWithEmailAndPassword(
              user.private.firebaseEmail,
              user.private.fortmaticID
            )
            .then((data) => {
              user.private.firebaseID = data.user.uid;

              this.database.ref("users/" + user.private.firebaseID).set(user);

              resolve(true);
            })
            .catch((error) => {
              switch (error.code) {
                case "auth/email-already-in-use":
                  // --- account is already in place, sign him in
                  this.auth.signInWithEmailAndPassword(
                    user.private.firebaseEmail,
                    user.private.fortmaticID
                  );
                  break;
                default:
                  alert(error.message);
                  break;
              }
            });
        }
      });
    });
  };

  checkFirebaseUserExists = (fortmaticID: string) => {
    return new Promise((resolve) => {
      const firebaseEmail: string = this.getFirebaseEmailFromUserID(
        fortmaticID
      );

      this.auth
        .signInWithEmailAndPassword(firebaseEmail, fortmaticID)
        .then((data: any) => {
          resolve(data.user.uid);
        })
        .catch((error) => {
          resolve();
        });
    });
  };

  checkUsernameExists = (username: string) => {
    return new Promise((resolve) => {
      this.database
        .ref("users")
        .orderByChild("username")
        .equalTo(username)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const key = Object.keys(snapshot.val())[0];
            resolve(key);
          } else {
            resolve(null);
          }
        });
    });
  };

  getUserDetails = (id: string) => {
    return new Promise((resolve) => {
      this.database.ref(`users/${id}`).once("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null);
        }
      });
    });
  };

  checkKeyExists = (path: string, key: string) => {
    return new Promise((resolve) => {
      this.database.ref(`${path}${key}`).once("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null);
        }
      });
    });
  };

  getRandomUser = () => {
    return new Promise((resolve) => {
      this.database
        .ref("users")
        .orderByChild("username")
        .once("value", (snapshot) => {
          const users = snapshot.val();

          const keys = Object.keys(users);
          const user = users[keys[(keys.length * Math.random()) << 0]];

          resolve(user);
        });
    });
  };

  getRandomCityUser = () => {
    return new Promise((resolve) => {
      this.database.ref("cities").once("value", async (snapshot) => {
        const cities = snapshot.val();

        const keys = Object.keys(cities);
        const cityKey = keys[(keys.length * Math.random()) << 0];

        if (cityKey) {
          resolve(await this.getUserDetails(cityKey));
        } else {
          resolve();
        }
      });
    });
  };
}

export default Firebase;
