import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { setupConfig } from "@ionic/react";

import Firebase, { FirebaseContext } from "./components/firebase";
import User, { UserContext } from "./components/user";
import Playcanvas, { PlaycanvasContext } from "./components/playcanvas";
import BuildMachine, { BuildMachineContext } from "./components/buildMachine";
import Analytics, { AnalyticsContext } from "./components/analytics";

// --- config
setupConfig({
  mode: "ios",
});

// --- providers
const PlaycanvasInstance = new Playcanvas();
const FirebaseInstance = new Firebase();
const AnalyticsInstance = new Analytics();
const BuildMachineInstance = new BuildMachine(FirebaseInstance);
const UserInstance = new User(
  FirebaseInstance,
  PlaycanvasInstance,
  BuildMachineInstance,
  AnalyticsInstance
);

BuildMachineInstance.setUser(UserInstance);

// --- select the iframe url based on the environment (dev/production)
const urlParams = new URLSearchParams(window.location.search);
let iframeUrl: string = "https://playcanv.as/index/PZNOfWCl";

if (
  urlParams.get("remoteIframe") !== "true" &&
  window.location.href.indexOf("localhost:") > -1
) {
  iframeUrl = "https://launch.playcanvas.com/843392?debug=true";
}

ReactDOM.render(
  <PlaycanvasContext.Provider value={PlaycanvasInstance}>
    <FirebaseContext.Provider value={FirebaseInstance}>
      <AnalyticsContext.Provider value={AnalyticsInstance}>
        <UserContext.Provider value={UserInstance}>
          <BuildMachineContext.Provider value={BuildMachineInstance}>
            <div className="iframe-container">
              <iframe
                id="game-iframe"
                className="game-iframe"
                src={iframeUrl}
              ></iframe>
            </div>
            <App />
          </BuildMachineContext.Provider>
        </UserContext.Provider>
      </AnalyticsContext.Provider>
    </FirebaseContext.Provider>
  </PlaycanvasContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
