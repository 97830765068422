import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonContent,
  IonPage,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
} from "@ionic/react";

import "./City.css";

import User, { UserContext } from "../components/user";
import { IUserData } from "../components/user/user";
import { EPlaycanvasEvents } from "../components/playcanvas";
import { RouteProps } from "react-router";

import SelectBuildingButtons from "./SelectBuildingButtons";
import CityStats from "./CityStats";

let cityIDLoaded: any;

export interface CityViewProps extends RouteProps {
  nickname: string;
  hideUI?: boolean;
}

const CityView: React.FC<CityViewProps> = (props) => {
  const user: User = useContext(UserContext);

  let { nickname } = useParams();

  if (props.nickname) {
    nickname = props.nickname;
  }

  const [userDetails, setUserDetails] = useState<IUserData>({});
  const [cityReady, setCityReady] = useState(true);

  const [buildingSelected, setBuildingSelected] = useState({
    state: false,
    id: undefined,
  });

  const [cityStats, setCityStats] = useState({
    buildings: 0,
    roads: 0,
    roadsLength: 0,
  });

  useEffect(() => {
    user.checkUsernameExists(nickname).then(async (userID) => {
      if (!userID) return;

      const details: IUserData = await user.getUserDetails(userID as string);
      setUserDetails(details);

      if (cityIDLoaded === userID) {
        setCityReady(true);
        return;
      }

      user.subscribeToCityChanges(userID as string);

      const promises = [user.prepareCityBuildingsFromDB(userID as string)];

      setCityReady(false);

      Promise.all(promises).then(() => {
        setCityReady(true);
        user.states.cityReady = true;

        cityIDLoaded = userID;

        console.log(`Viewing City: ${nickname}, for userID: ${userID}`);

        user.analytics.track("CityView", `Loaded City for user ${nickname}.`, {
          id: userID,
        });

        user.playcanvas.sendMessage(EPlaycanvasEvents.City_PickerState, {
          canSelectActors: true,
          canMoveActors: false,
        });
        user.playcanvas.sendMessage(
          EPlaycanvasEvents.City_SerializeState,
          false
        );

        user.playcanvas.sendMessage(EPlaycanvasEvents.City_StatsRequest);
      });

      user.playcanvas.on(EPlaycanvasEvents.City_StatsUpdated, (stats: any) => {
        setCityStats(stats);
      });

      user.playcanvas.on(
        EPlaycanvasEvents.City_SelectedBuilding,
        (id: string) => {
          setBuildingSelected({
            state: true,
            id: id,
          });
        }
      );

      user.playcanvas.on(
        EPlaycanvasEvents.City_DeSelectedBuilding,
        (id: string) => {
          setBuildingSelected({
            state: false,
            id: undefined,
          });
        }
      );
    });
  }, []);

  if (props.hideUI === true) {
    return <div></div>;
  }

  return cityReady === false ? (
    <div>
      <IonLoading isOpen={true} message={"Building..."} duration={0} />
    </div>
  ) : (
    <IonPage className="disable-pointer-events">
      <IonContent className="transparent-content-background">
        {buildingSelected.state === false ? (
          <IonGrid className="header-grid">
            <IonRow className="no-padding">
              <IonCol size="4" className="no-padding">
                <IonRouterLink
                  class="enable-pointer-events"
                  routerLink="/about"
                  routerDirection="back"
                >
                  <div className="top-nav-outline">
                    <div className="top-nav-button">
                      <img
                        className="nav-icon"
                        src={require("../images/home-icon.svg")}
                      ></img>
                    </div>
                  </div>
                </IonRouterLink>

                <div className="counter-div">
                  <p className="counter-text enable-pointer-events">
                    {userDetails.username}'s City
                  </p>
                </div>
                <CityStats
                  buildings={cityStats.buildings}
                  roads={cityStats.roads}
                  roadsLength={cityStats.roadsLength}
                />
              </IonCol>

              <IonCol size="4">
                <a
                  className="enable-pointer-events"
                  href="https://www.blockcities.co"
                  target="_blank"
                >
                  <div>
                    <img
                      className="city-logo"
                      src={require("../images/logo-white-border.svg")}
                    ></img>
                  </div>
                </a>
              </IonCol>

              <IonCol size="4" className="no-padding">
                <IonRouterLink
                  class="enable-pointer-events"
                  routerLink={`/user/${nickname}`}
                >
                  <div className="top-nav-outline right">
                    <div className="top-nav-button">
                      <img
                        className="nav-icon"
                        src={require("../images/user-icon.svg")}
                      ></img>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <div></div>
        )}

        {buildingSelected.state === true ? (
          <IonGrid className="header-grid">
            <IonRow className="no-padding">
              <SelectBuildingButtons
                buildingSelected={buildingSelected}
                showRemove={false}
              />
            </IonRow>
          </IonGrid>
        ) : (
          <div></div>
        )}

        <div className="discord-group-container">
          <a
            className="discord-group enable-pointer-events"
            href="https://discord.gg/84JrveD"
            target="_blank"
          >
            <img
              className="discord-logo"
              src={require("../images/discord-logo/Discord-Logo-White.svg")}
            ></img>
            <p className="discord-text">Join Beta Waitlist</p>
          </a>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CityView;
