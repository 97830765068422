import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonRouterLink,
} from "@ionic/react";
import React, { useState, useContext, useEffect } from "react";
import "./City.css";

import BottomSheet from "./BottomSheet";
import SelectBuildingButtons from "./SelectBuildingButtons";
import RoadModeButtons from "./RoadModeButtons";
import CityStats from "./CityStats";

import * as routes from "../constants/routes";
import User, { UserContext } from "../components/user";
import { EPlaycanvasEvents } from "../components/playcanvas";

const buildingsToUnveil: number[] = [];

const City: React.FC = () => {
  const user: User = useContext(UserContext);

  const [buildingsReady, setBuildingsReady] = useState(
    user.states.buildingsReady
  );

  const [constructing, setConstructing] = useState(user.states.constructing);
  const [unveilAvailable, setUnveilAvailable] = useState(
    user.states.unveilAvailable
  );
  const [buildingSelected, setBuildingSelected] = useState({
    state: false,
    id: undefined,
  });
  const [roadBuilding, setRoadBuilding] = useState({
    state: false,
    type: "",
    displayName: "",
  });
  const [cityStats, setCityStats] = useState({
    buildings: 0,
    roads: 0,
    roadsLength: 0,
  });

  useEffect(() => {
    user.playcanvas.once(EPlaycanvasEvents.BuildingsReady, () => {
      setBuildingsReady(true);
      user.states.initialMessages = true;
    });

    user.playcanvas.on(EPlaycanvasEvents.Buildings_Constructing, () => {
      setConstructing(true);
      user.states.constructing = true;
    });

    user.playcanvas.on(
      EPlaycanvasEvents.Building_Unveil,
      (buildingID: number) => {
        if (user.buildMachine.mintedTokens.length === 0) {
          setConstructing(false);
          user.states.constructing = false;
        }

        if (buildingsToUnveil.indexOf(buildingID) === -1) {
          buildingsToUnveil.push(buildingID);
        }
        setUnveilAvailable(true);

        user.states.unveilAvailable = true;
      }
    );

    user.playcanvas.on(
      EPlaycanvasEvents.City_SelectedBuilding,
      (id: string) => {
        setBuildingSelected({
          state: true,
          id: id,
        });
      }
    );

    user.playcanvas.on(
      EPlaycanvasEvents.City_DeSelectedBuilding,
      (id: string) => {
        setBuildingSelected({
          state: false,
          id: undefined,
        });
      }
    );

    user.playcanvas.on(EPlaycanvasEvents.City_AddRoad, (data: any) => {
      setRoadBuilding({
        state: data.state,
        type: data.type,
        displayName: data.displayName,
      });
    });

    user.playcanvas.on(EPlaycanvasEvents.City_StatsUpdated, (stats: any) => {
      setCityStats(stats);
    });

    user.playcanvas.sendMessage(EPlaycanvasEvents.City_StatsRequest);
  }, []);

  return (
    <IonPage className="disable-pointer-events">
      <IonHeader></IonHeader>

      <IonContent className="transparent-content-background">
        <IonToast
          isOpen={user.states.initialMessages === false && !buildingsReady}
          position="top"
          message="Loading your buildings..."
          duration={2500}
        />
        <IonToast
          isOpen={user.states.initialMessages === false && buildingsReady}
          position="top"
          message="Time to build!"
          duration={2500}
        />

        <IonGrid className="header-grid">
          {constructing === true ? (
            <IonRow className="no-padding">
              <IonCol className="no-padding" size="12">
                <div className="info-flex">
                  <div className="construction-div">
                    <p className="construction-text">🚧</p>
                    <p className="construction-text italic">IN CONSTRUCTION</p>
                    <p className="construction-text">🚧</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow></IonRow>
          )}
          {buildingSelected.state === false && roadBuilding.state === false ? (
            <IonRow className="no-padding">
              <IonCol size="6" className="no-padding">
                <IonRouterLink
                  class="enable-pointer-events"
                  routerLink="/about"
                  routerDirection="back"
                >
                  <div className="top-nav-outline">
                    <div className="top-nav-button">
                      <img
                        className="nav-icon"
                        src={require("../images/home-icon.svg")}
                      ></img>
                    </div>
                  </div>
                </IonRouterLink>

                <div className="counter-div">
                  <p className="counter-text enable-pointer-events">
                    {user.current.username}'s City
                  </p>
                </div>
                <CityStats
                  buildings={cityStats.buildings}
                  roads={cityStats.roads}
                  roadsLength={cityStats.roadsLength}
                />
              </IonCol>

              <IonCol size="6" className="no-padding">
                <IonRouterLink
                  class="enable-pointer-events"
                  routerLink={routes.USER}
                >
                  <div className="top-nav-outline right">
                    <div className="top-nav-button">
                      <img
                        className="nav-icon"
                        src={require("../images/user-icon.svg")}
                      ></img>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow></IonRow>
          )}

          {buildingSelected.state === true ? (
            <SelectBuildingButtons
              buildingSelected={buildingSelected}
              showRemove={true}
            />
          ) : (
            <IonRow></IonRow>
          )}

          {roadBuilding.state === true ? (
            <RoadModeButtons roadType={roadBuilding.displayName} />
          ) : (
            <IonRow></IonRow>
          )}
        </IonGrid>
        {unveilAvailable === true ? (
          <div
            className="unveil-button-outline enable-pointer-events"
            onClick={() => {
              const buildingID: number = buildingsToUnveil.shift();

              if (buildingID) {
                user.playcanvas.sendMessage(
                  EPlaycanvasEvents.City_AddBuilding,
                  buildingID
                );
              }

              if (buildingsToUnveil.length === 0) {
                setUnveilAvailable(false);
                user.states.unveilAvailable = false;
              }
            }}
          >
            <div className="unveil-button">Unveil Building</div>
          </div>
        ) : (
          <div></div>
        )}
        {buildingsReady === true ? <BottomSheet /> : <div></div>}
      </IonContent>
    </IonPage>
  );
};

export default City;
