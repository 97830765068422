import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import City from "./pages/City";
import UserProfile from "./pages/UserProfile";
import Wallet from "./pages/Wallet";
import Settings from "./pages/Settings";
import AboutPage from "./pages/AboutPage";
import Start from "./pages/Start";
import SignUp from "./pages/SignUp";
import CityView from "./pages/CityView";
import BuildingProfile from "./pages/BuildingProfile";
import MysteryMachine from "./pages/machines/MysteryMachine";

import User, { UserContext } from "./components/user";
import PrivateRoute from "./components/user/privateRoute";

import * as routes from "./constants/routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import BrooklynKiosk from "./pages/machines/BrooklynKiosk";

const App: React.FC = (props) => {
  const user: User = useContext(UserContext);

  const [loggedIn, setLoggedIn] = useState(user.isLoggedIn);

  // --- debug tests
  //user.doSignOut();
  //user.checkUsernameExists("leo").then(key => console.log(key));

  // --- based on the url check our initial starting mode
  const checkMode = () => {
    let paths = window.location.pathname.split("/");

    // --- public urls
    const publicUrls = ["/mystery-machine"];

    // --- remove zero values
    paths = paths.filter((path) => path !== "");

    return publicUrls.indexOf(window.location.pathname) > -1 ||
      paths.length === 2
      ? "read"
      : "full";
  };

  useEffect(() => {
    user.playcanvas.connectToIframe();
  }, []);

  useEffect(() => {
    // --- check if we are attempting a full user log in or we are viewing a city
    const promises = [user.buildMachine.prepareBuildings()];
    const mode: string = checkMode();

    if (mode === "full") {
      promises.push(user.checkLoggedIn(false));
    } else {
      promises.push(user.checkLoggedIn(false));
    }

    Promise.all(promises).then(async () => {
      if (user.isLoggedIn === true && mode === "full") {
        await user.prepareUser();
      }

      setLoggedIn(user.isLoggedIn);
    });
  }, []);

  return loggedIn === undefined ? (
    <div>
      <IonLoading isOpen={true} message={"Building..."} duration={0} />
    </div>
  ) : (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path={routes.START} component={Start} />
          <Route path={routes.SIGNUP} component={SignUp} />
          <Route path={routes.SIGNIN} component={SignUp} />
          <Route path={routes.MYSTERY_MACHINE} component={MysteryMachine} />
          <Route path={routes.BROOKLYN_KIOSK} component={BrooklynKiosk} />
          <Route path={routes.CITY_VIEW} component={CityView} exact={true} />
          <PrivateRoute path={routes.CITY} component={City} exact={true} />
          <PrivateRoute
            path={routes.USER}
            component={UserProfile}
            acceptEthereum={true}
            exact={true}
          />
          <Route
            path={routes.BUILDING_VIEW}
            component={BuildingProfile}
            exact={true}
          />
          <Route path={routes.USER_VIEW} component={UserProfile} exact={true} />
          <Route path={routes.ABOUTPAGE} component={AboutPage} exact={true} />
          <PrivateRoute path={routes.WALLET} component={Wallet} />
          <PrivateRoute path={routes.SETTINGS} component={Settings} />
          <Route
            path="/"
            render={() => <Redirect to={routes.CITY} />}
            exact={true}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
