declare var document: any;

export enum EPlaycanvasEvents {
  IFrameReady = "IFrameReady",
  ParseBuildings = "ParseBuildings",
  BuildingsReady = "BuildingsReady",
  Buildings_Constructing = "Buildings_Constructing",
  Building_Ready = "Building_Ready",
  Building_Available = "Building_Available",
  Building_Unavailable = "Building_Unavailable",
  Building_Unveil = "Buildings_Unveil",
  CityReady = "CityReady",
  City_AddBuilding = "City_AddBuilding",
  City_AddRoad = "City_AddRoad",
  City_SelectedBuilding = "City_SelectedBuilding",
  City_RemoveSelectedBuilding = "City_RemoveSelectedBuilding",
  City_DeSelectedBuilding = "City_DeSelectedBuilding",
  City_SaveToDB = "City_SaveToDB",
  City_LoadFromDB = "City_LoadFromDB",
  City_PickerState = "City_PickerState",
  City_SerializeState = "City_SerializeState",
  City_StatsUpdated = "City_StatsUpdated",
  City_StatsRequest = "City_StatsRequest",
  Actor_UpdateKey = "Actor_UpdateKey",
  CityView_ActorAdded = "CityView_ActorAdded",
  CityView_ActorUpdated = "CityView_ActorUpdated",
  CityView_ActorRemoved = "CityView_ActorRemoved",
}

interface IEventHandler {
  eventType: EPlaycanvasEvents;
  callback: Function;
  context?: any;
  once: boolean;
}

class Playcanvas {
  private iframeContent: any;
  private iframeReady: boolean;
  private messagesQueue: any[];

  private eventHandlers: IEventHandler[];

  private handlingEvents: boolean;
  private eventHandlers_New: IEventHandler[];

  constructor() {
    this.iframeReady = false;
    this.messagesQueue = [];
    this.eventHandlers = [];

    this.handlingEvents = false;
  }

  connectToIframe() {
    // @ts-ignore
    this.iframeContent = document.getElementById("game-iframe").contentWindow;

    // --- postMessage event handlers
    window.addEventListener("message", this.parseMessage.bind(this));
  }

  parseMessage(event: any) {
    if (!event.data || !event.data.type) return;

    switch (event.data.type) {
      case EPlaycanvasEvents.IFrameReady:
        this.iframeReady = true;

        // --- send queue messages
        this.messagesQueue.forEach((message) => {
          this.sendMessage(message.type, message.data);
        });
        break;
    }

    this.handleEvents(event.data.type, event.data.data);
  }

  handleEvents(type: EPlaycanvasEvents, data?: any) {
    const toKeep: IEventHandler[] = [];

    this.handlingEvents = true;
    this.eventHandlers_New = [];

    this.eventHandlers.forEach((event) => {
      if (event.eventType === type) {
        event.callback.call(event.context, data);

        if (event.once !== true) toKeep.push(event);
      } else {
        toKeep.push(event);
      }
    });

    this.handlingEvents = false;

    this.eventHandlers = toKeep;

    // --- add event hanlders attached during the handle events run
    this.eventHandlers_New.forEach((event) => {
      this.eventHandlers.push(event);
    });
  }

  on(type: EPlaycanvasEvents, callback: Function, context?: any) {
    const event: IEventHandler = {
      eventType: type,
      callback: callback,
      context: context,
      once: false,
    };

    this.eventHandlers.push(event);

    if (this.handlingEvents) {
      this.eventHandlers_New.push(event);
    }
  }

  once(type: EPlaycanvasEvents, callback: Function, context?: any) {
    const event: IEventHandler = {
      eventType: type,
      callback: callback,
      context: context,
      once: true,
    };

    this.eventHandlers.push(event);

    if (this.handlingEvents) {
      this.eventHandlers_New.push(event);
    }
  }

  sendMessage(type: EPlaycanvasEvents, data?: any) {
    // --- handle in this context messaging
    this.handleEvents(type, data);

    // --- if we aren't ready, queue messages for sending later
    if (this.iframeReady === false) {
      this.messagesQueue.push({
        type: type,
        data: data,
      });
      return;
    }

    this.iframeContent.postMessage(
      {
        type: type,
        data: data,
      },
      "*"
    );
  }
}

export default Playcanvas;
