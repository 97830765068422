import * as mixpanel from "mixpanel-browser";

class Analytics {
  constructor() {
    mixpanel.init("4ce632f99985889e49809e899cf33dd1");
  }

  identifyUser(userID: string, data?: any) {
    mixpanel.identify(userID);

    if (data) mixpanel.people.set(data);
  }

  track(className: string, eventName: string, options?: any) {
    mixpanel.track(`${className}: ${eventName}`, options);
  }
}

export default Analytics;
