import React, { useState, useContext, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonModal,
  useIonViewWillEnter,
  IonRouterLink,
} from "@ionic/react";
import { RouteProps } from "react-router";
import "./BrooklynKiosk.css";

import User, { UserContext } from "../../components/user";

import PublicNavButtons from "../PublicNavButtons";

export interface BrooklynKioskProps extends RouteProps {
  setStateModal: Function;
  useModal: boolean;
}

let validator: any;
let backgroundsConfig: any;
let animatorInterval: any;
let validatorInterval: any;
let canAnimate: any;

const BrooklynKiosk: React.FC<BrooklynKioskProps> = (
  props: BrooklynKioskProps
) => {
  const user: User = useContext(UserContext);

  const [stateModal, setStateModal] = useState(true);
  const [timeSeconds, setTimeSeconds] = useState("00");
  const [timeMinutes, setTimeMinutes] = useState("00");
  const [messageInfoState, setMessageInfoState] = useState(false);

  const [prices, setPrices] = useState({
    currentPrice: 0,
    lastPrice: 0,
    currentPriceStr: "0.00",
    lastPriceStr: "0.00",
    currentPriceUsdStr: "0.00",
    lastPriceUsdStr: "0.00",
  });

  const [building, setBuilding] = useState({
    building: null,
    base: null,
    body: null,
    roof: null,
    exteriors: null,
    background: null,
    url: null,
    svg: null,
  });

  const [animateBuildingState, setAnimateBuildingState] = useState("reveal");

  const [activeState, setActiveState] = useState("intro");
  const [allowance, setAllowance] = useState({
    remaining: 0,
    total: 0,
  });

  useIonViewWillEnter(() => {
    setStateModal(true);
  });

  const sampleArray = (arr: any) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  const sampleObject = function (obj: any) {
    var keys = Object.keys(obj);
    return obj[keys[(keys.length * Math.random()) << 0]];
  };

  const buildingExists = async (data: any) => {
    const hash: string = await user.buildMachine.hashParams(
      data.building,
      data.base,
      data.body,
      data.roof,
      "0",
      data.exteriors
    );

    return await user.buildMachine.limitedVendingContract.buildingRegistry(
      hash
    );
  };

  const buildingValid = async (data: any) => {
    if (user.buildMachine.networkID === 4) {
      return true;
    }

    return await user.buildMachine.cityBuildingValidator.validate(
      data.building,
      data.base,
      data.body,
      data.roof,
      data.exteriors
    );
  };

  let passing = false;

  const getRandomBuilding = async (checkRegistry: boolean = true) => {
    if (!validator || !backgroundsConfig || passing === true) return;
    passing = true;

    // @ts-ignore
    //setBuilding({});

    let data: any = {};

    // --- get samples to create combo
    const sample = sampleArray(validator);
    data.building = sample.building;

    data.base = sampleArray(sample.base);
    data.body = sampleArray(sample.body);
    data.roof = sampleArray(sample.roof);
    data.exteriors = sampleArray(sample.exteriors);

    data.background = sampleObject(backgroundsConfig);

    // if (window.bcOverride) {
    //   data.building = 34;
    //   // @ts-ignore
    //   data.body = window.bcOverride.body;
    //   // @ts-ignore
    //   data.roof = window.bcOverride.roof;
    //   // @ts-ignore
    //   data.exteriors = window.bcOverride.exteriors;
    //   data.background = backgroundsConfig.AQUA;
    // }

    // --- check if combo is complete
    if (
      !data.base ||
      !data.body ||
      !data.roof ||
      !data.exteriors ||
      !data.background
    ) {
      passing = false;
      getRandomBuilding(checkRegistry);
      return;
    }

    // --- check if combo is available
    if (checkRegistry) {
      try {
        if (
          (await buildingExists(data)) === true ||
          (await buildingValid(data)) === false
        ) {
          passing = false;
          getRandomBuilding(checkRegistry);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    // --- assemble the url and preload image
    data.url = `https://us-central1-block-cities.cloudfunctions.net/api/builder/${data.building}/base/${data.base}/body/${data.body}/roof/${data.roof}/exterior/${data.exteriors}/svg`;

    const responseImage = await fetch(data.url);
    const blob = new Blob([await responseImage.text()], {
      type: "image/svg+xml",
    });
    data.svg = URL.createObjectURL(blob);

    // --- handle building drop animation
    setAnimateBuildingState("drop");
    window.setTimeout(() => {
      passing = false;
      setBuilding(data);
      setAnimateBuildingState("reveal");
    }, 500);
  };

  const calculateAllowance = async () => {
    const allowanceData = await user.buildMachine.getLimitedBuildingsAllowance();
    setAllowance(allowanceData);
  };

  const refreshValidator = async () => {
    // --- refresh sets
    const response = await fetch(
      "https://us-central1-block-cities.cloudfunctions.net/api/builder/network/1/validator/current"
    );
    validator = await response.json();
  };

  const minutesNonActive = [59, 0, 1, 29, 30, 31];

  const [rotating, SetRotating] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      let minutes: number = new Date().getMinutes();

      // --- check if we are in a building span
      if (minutesNonActive.indexOf(minutes) !== -1) {
        SetRotating(true);
      } else {
        SetRotating(false);
      }

      if (minutes > 30) {
        minutes = 60 - minutes;
      } else {
        minutes = 30 - minutes;
      }

      setTimeMinutes(String(minutes).padStart(2, "0"));

      const seconds: number = 59 - new Date().getSeconds();
      setTimeSeconds(String(seconds).padStart(2, "0"));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [timeSeconds]);

  useEffect(() => {
    canAnimate = true;

    user.buildMachine
      .prepare()
      .then(async () => {
        await calculateAllowance();

        // --- get validator data
        await refreshValidator();

        // --- get backgrounds config
        if (!backgroundsConfig) {
          const responseBackgrounds = await fetch(
            "https://us-central1-block-cities.cloudfunctions.net/api/configs/backgrounds"
          );
          backgroundsConfig = await responseBackgrounds.json();
        }

        // --- start random building generator interval
        getRandomBuilding();

        if (canAnimate === true) {
          animatorInterval = window.setInterval(() => {
            getRandomBuilding();
          }, 3500);

          validatorInterval = window.setInterval(() => {
            refreshValidator();

            calculateAllowance();
          }, 30000);
        }

        // --- get prices
        const pricesData: any = await user.buildMachine.getPrices("limited");
        setPrices(pricesData);
      })
      .catch(() => {
        if (props.useModal === true) props.setStateModal(false);
      });

    return () => {
      window.clearInterval(animatorInterval);

      window.clearInterval(validatorInterval);
    };
  }, []);

  const mainComponent: any = (
    <div
      className="enable-pointer-events build-page"
      style={{
        backgroundColor: building.background
          ? "#" + building.background.hex
          : "#F7DCCB",
      }}
    >
      <div className="build-content">
        <div className="build-page-div">
          {props.useModal === true ? (
            <img
              className="tab-icon"
              src="https://uploads-ssl.webflow.com/5d391fec3211a2242af4f911/5e0ad539edb7542d0caedf8d_Vector%206-1.svg"
              onClick={() => {
                if (props.useModal === true) props.setStateModal(false);
              }}
            />
          ) : (
            <div></div>
          )}

          <div className="kiosk-container">
            {props.useModal === true ? (
              <div></div>
            ) : (
              <div className="public-nav-buttons">
                <PublicNavButtons
                  currentPath={props.location}
                  setStateModal={setStateModal}
                />
              </div>
            )}
            <div className="price-ticker"></div>
            <div className="ticker-flex text-slideshow">
              <div className="text-slide-1">
                <div className="ticker-text">
                  current price: ${prices.currentPriceUsdStr}
                </div>
                <div className="ticker-text _50">
                  Last price: ${prices.lastPriceUsdStr}
                </div>
                <div className="ticker-text">
                  current price: ${prices.currentPriceUsdStr}
                </div>
                <div className="ticker-text _50">
                  Last price: ${prices.lastPriceUsdStr}
                </div>
              </div>
              <div className="text-slide-2">
                <div className="ticker-text">
                  current price: ${prices.currentPriceUsdStr}
                </div>
                <div className="ticker-text _50">
                  Last price: ${prices.lastPriceUsdStr}
                </div>
                <div className="ticker-text">
                  current price: ${prices.currentPriceUsdStr}
                </div>
                <div className="ticker-text _50">
                  Last price: ${prices.lastPriceUsdStr}
                </div>
              </div>
            </div>
            <div className="div-block">
              <div className="sign">
                <div className="text-block">Brooklyn Collection</div>
              </div>
            </div>
            <div className="kiosk-sign">
              <div className="kiosk-text">kiosk</div>
            </div>
            <div className="kiosk-info">
              <div className="kiosk-left">
                <div className="current-price">
                  <div className="kiosk-subheading">current price</div>
                  <div className="current-price-text">
                    ${prices.currentPriceUsdStr}
                  </div>
                </div>

                <div
                  className="current-stock info"
                  onClick={() => {
                    setMessageInfoState(!messageInfoState);
                  }}
                >
                  <div className="current-price-text smallest">?</div>
                  <div className="kiosk-subheading right">info</div>
                </div>
              </div>

              {messageInfoState === true ? (
                <div className="kiosk-info-modal">
                  <div className="kiosk-info-heading">
                    <div className="text-block _150">Welcome to the kiosk</div>
                  </div>
                  <div className="kiosk-body-text">
                    Brooklyn Buildings will be randomly presented to you.
                    <br />
                    <br />
                    Hit “Build” and the building is yours forever.
                    <br />
                    <br />
                    Hit “Pass” to get a new random building.
                  </div>
                  <div className="kiosk-body-text dark">
                    Only 250 Brooklyn Buildings can ever be built.
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="building-gif brooklyn-building">
                {building.svg !== undefined ? (
                  <img
                    src={building.svg}
                    sizes="(max-width: 479px) 175.00001525878906px, (max-width: 767px) 39vw, 200.00001525878906px"
                    alt=""
                    className={`building-gif-image animate-${animateBuildingState}`}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              <div className="kiosk-right">
                <div className="current-price">
                  <div className="kiosk-subheading">Buildings Change</div>
                  <div className="current-price-text">
                    {rotating === false
                      ? `${timeMinutes}:${timeSeconds}`
                      : "..."}
                  </div>
                </div>
                <div className="current-stock">
                  <div className="current-price-text smallest">
                    {allowance.remaining}/{allowance.total}
                  </div>
                  <div className="kiosk-subheading right">left</div>
                </div>
              </div>
            </div>
            {rotating === false && activeState === "intro" ? (
              <div
                className="build-button-outline"
                onClick={() => {
                  canAnimate = false;
                  setActiveState("preview");

                  window.clearInterval(animatorInterval);
                }}
              >
                <div className="build-button brooklyn">
                  <div className="buy-button-text">Generate Building</div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {rotating === false && activeState === "preview" ? (
              <div className="build-buttons">
                <div
                  className="build-button-outline build-button-inline"
                  onClick={async () => {
                    await getRandomBuilding();
                  }}
                >
                  <div className="build-button pass">
                    <div className="buy-button-text">Pass</div>
                  </div>
                </div>
                <div
                  className="build-button-outline build-button-inline"
                  onClick={async () => {
                    setActiveState("building");

                    let buildingBackgroundColor = "fff";
                    if (building.background) {
                      buildingBackgroundColor = building.background.hex;
                      building.background.hex = "FFEFB6";
                    }

                    let token;

                    if (
                      (await buildingExists(building)) === true ||
                      (await buildingValid(building)) === false
                    ) {
                    } else {
                      token = await user.buildMachine.buildLimitedNew(building);
                    }

                    building.background.hex = buildingBackgroundColor;

                    if (token) {
                      setActiveState("purchased");

                      calculateAllowance();
                    } else {
                      setActiveState("preview");
                    }
                  }}
                >
                  <div className="build-button brooklyn">
                    <div className="buy-button-text">Build</div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {rotating === true ? (
              <div className="build-button-outline">
                <div className="build-button grey">
                  <div className="buy-button-text">Changing Buildings...</div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {rotating === false && activeState === "building" ? (
              <div
                className="build-button-outline"
                style={{ backgroundColor: "transparent" }}
              >
                <div className="constructing-banner">
                  <img
                    className="constructing-emoji"
                    src={require("../../images/hammer-and-pick_2692.png")}
                  ></img>

                  <div className="constructing-text">Constructing</div>

                  <img
                    className="constructing-emoji"
                    src={require("../../images/hammer-and-wrench_1f6e0.png")}
                  ></img>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {rotating === false && activeState === "purchased" ? (
              <IonRouterLink
                routerLink="/user/"
                className="build-button-outline"
                onClick={() => {
                  setStateModal(false);
                }}
              >
                <div className="build-button brooklyn">
                  <div className="buy-button-text">View in Collection</div>
                </div>
              </IonRouterLink>
            ) : (
              <div></div>
            )}
            <div className="div-block-3"></div>
          </div>
        </div>
      </div>
    </div>
  );

  return props.useModal === true ? (
    mainComponent
  ) : (
    <IonPage>
      <IonContent className="custom-background">
        <IonModal
          id="modal-build-brooklyn-kiosk"
          isOpen={stateModal}
          showBackdrop={false}
          backdropDismiss={false}
        >
          {mainComponent}
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default BrooklynKiosk;
