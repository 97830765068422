import { build } from "ionicons/icons";

export const ROOFTOP_BROWN = "https://i.ibb.co/wWg9YFT/Group.png";
export const ROOFTOP_WHITE = "https://i.ibb.co/wWg9YFT/Group.png";

export const COLOR_AQUABLUE = require("../images/profile-icons/color-icons/aquablue.png");
export const COLOR_BLACK = require("../images/profile-icons/color-icons/black.png");
export const COLOR_BLUEGREY = require("../images/profile-icons/color-icons/bluegrey.png");
export const COLOR_BLUSH = require("../images/profile-icons/color-icons/blush.png");
export const COLOR_BOLDBLUE = require("../images/profile-icons/color-icons/boldblue.png");
export const COLOR_BRICKRED = require("../images/profile-icons/color-icons/brickred.png");
export const COLOR_CARBON = require("../images/profile-icons/color-icons/carbon.png");
export const COLOR_COOLGREY = require("../images/profile-icons/color-icons/coolgrey.png");
export const COLOR_DARKBROWN = require("../images/profile-icons/color-icons/darkbrown.png");
export const COLOR_DARKGREY = require("../images/profile-icons/color-icons/darkgrey.png");
export const COLOR_DIJON = require("../images/profile-icons/color-icons/dijon.png");
export const COLOR_GOLD = require("../images/profile-icons/color-icons/gold.png");
export const COLOR_GOLDENBEIGE = require("../images/profile-icons/color-icons/goldenbeige.png");
export const COLOR_LIGHTBEIGE = require("../images/profile-icons/color-icons/lightbeige.png");
export const COLOR_LIGHTBRICKRED = require("../images/profile-icons/color-icons/lightbrickred.png");
export const COLOR_LIGHTCARBON = require("../images/profile-icons/color-icons/lightcarbon.png");
export const COLOR_LIGHTGREY = require("../images/profile-icons/color-icons/lightgrey.png");
export const COLOR_LIMESTONE = require("../images/profile-icons/color-icons/limestone.png");
export const COLOR_NAVY = require("../images/profile-icons/color-icons/navy.png");
export const COLOR_RED = require("../images/profile-icons/color-icons/red.png");
export const COLOR_TRUEBLUE = require("../images/profile-icons/color-icons/trueblue.png");
export const COLOR_TURQUOISE = require("../images/profile-icons/color-icons/turquoise.png");

export const selectColorIcon = (colorName: string) => {
  // prettier-ignore
  switch (colorName) {
    case "Aqua Blue": return COLOR_AQUABLUE;
    case "Black": return COLOR_BLACK;
    case "Blue Grey": return COLOR_BLUEGREY;
    case "Blush": return COLOR_BLUSH;
    case "Bold Blue": return COLOR_BOLDBLUE;
    case "Brick Red": return COLOR_BRICKRED;
    case "Carbon": return COLOR_CARBON;
    case "Cool Grey": return COLOR_COOLGREY;
    case "Dark Brown": return COLOR_DARKBROWN;
    case "Dark Grey": return COLOR_DARKGREY;
    case "Dijon": return COLOR_DIJON;
    case "Gold": return COLOR_GOLD;
    case "Light Beige": return COLOR_LIGHTBEIGE;
    case "Light Brick Red": return COLOR_LIGHTBRICKRED;
    case "Light Carbon": return COLOR_LIGHTCARBON;
    case "Light Grey": return COLOR_LIGHTGREY;
    case "Limestone": return COLOR_LIMESTONE;
    case "Navy": return COLOR_NAVY;
    case "Red": return COLOR_RED;
    case "True Blue": return COLOR_TRUEBLUE;
    case "Turquoise": return COLOR_TURQUOISE;
    default:
      return "";
  }
};

export const PROFILE_APARTMENT = require("../images/profile-icons/apartment.png");
export const PROFILE_ATTRACTION = require("../images/profile-icons/attraction.png");
export const PROFILE_BRONZE = require("../images/profile-icons/bronze.png");
export const PROFILE_BUSINESS = require("../images/profile-icons/business.png");
export const PROFILE_CONDO = require("../images/profile-icons/condo.png");
export const PROFILE_FOAM = require("../images/profile-icons/foam.png");
export const PROFILE_FOUNDER_SPECIAL = require("../images/profile-icons/founder-special.png");
export const PROFILE_GENESIS = require("../images/profile-icons/genesis-building.png");
export const PROFILE_GLASS_CURTAIN = require("../images/profile-icons/glass-curtain.png");
export const PROFILE_GOLD = require("../images/profile-icons/gold.png");
export const PROFILE_SILVER = require("../images/profile-icons/silver.png");
export const PROFILE_HOTEL = require("../images/profile-icons/hotel.png");
export const PROFILE_HORIZONTAL_WINDOW = require("../images/profile-icons/horizontal-window.png");
export const PROFILE_NORMAL_WINDOW = require("../images/profile-icons/normal-window.png");
export const PROFILE_VERTICAL_WINDOW = require("../images/profile-icons/vertical-window.png");
export const PROFILE_SCAVENGER_SPECIAL = require("../images/profile-icons/scavenger-special.png");
export const PROFILE_GOVERNMENT = require("../images/profile-icons/government.png");
export const PROFILE_RETAIL = require("../images/profile-icons/retail.png");
export const PROFILE_TRANSIT = require("../images/profile-icons/transit.png");

export const selectWindowIcon = (type: string) => {
  switch (type) {
    case "Glass Curtain":
      return PROFILE_GLASS_CURTAIN;
    case "Horizontal Window":
      return PROFILE_HORIZONTAL_WINDOW;
    case "Vertical Window":
      return PROFILE_VERTICAL_WINDOW;
    case "Normal Window":
      return PROFILE_NORMAL_WINDOW;
    default:
      return "";
  }
};

export const selectUseIcon = (type: string) => {
  switch (type) {
    case "Apartment":
      return PROFILE_APARTMENT;
    case "Attraction":
      return PROFILE_ATTRACTION;
    case "Business":
      return PROFILE_BUSINESS;
    case "Condo":
      return PROFILE_CONDO;
    case "Hotel":
      return PROFILE_HOTEL;
    case "Government":
      return PROFILE_GOVERNMENT;
    case "Retail":
      return PROFILE_RETAIL;
    case "Transit":
      return PROFILE_TRANSIT;
    default:
      return "";
  }
};

export const getBuildingBadges = (building: any) => {
  const badges: any = [];

  if (building) {
    // --- genesis
    if (building.id === 1) badges.push(PROFILE_GENESIS);

    // --- founders
    if (building.id <= 500) badges.push(PROFILE_FOUNDER_SPECIAL);

    // --- transit
    if (
      building.attributes.rooftopType === "Transit" ||
      building.attributes.groundFloorType === "Transit"
    )
      badges.push(PROFILE_TRANSIT);

    // --- gold, silver, bronze
    if (
      building.id === 2 ||
      building.id === 3 ||
      building.id === 4 ||
      building.id === 5 ||
      building.id === 1377
    )
      badges.push(PROFILE_GOLD);
    if (building.id === 1378) badges.push(PROFILE_SILVER);
    if (building.id === 1379) badges.push(PROFILE_BRONZE);
  }

  // debug
  // badges.push(PROFILE_GENESIS, PROFILE_GOLD, PROFILE_TRANSIT);

  return badges;
};
