import Fortmatic from "fortmatic";
import Web3 from "web3";
import Firebase from "../firebase";
import Playcanvas, { EPlaycanvasEvents } from "../playcanvas";
import BuildMachine from "../buildMachine";
import Analytics from "../analytics";

declare var window: any;

export interface IUserData {
  private?: {
    email?: string;
    phone?: string;
    provider?: string;
    fortmaticID?: string;
    firebaseID?: string;
    firebaseEmail?: string;
  };
  publicAddress?: string;
  fullname?: string;
  username?: string;
}

export interface IBalance {
  eth: number;
  usd: number;
  ethStr: string;
  usdStr: string;
}

class User {
  public firebase: Firebase;
  public playcanvas: Playcanvas;
  public buildMachine: BuildMachine;
  public analytics: Analytics;

  public current: IUserData;
  public fm: any;
  public provider: any;
  public web3: any;

  public tokens: any;

  private sessionID: string;
  private cityAddListener: any;
  private cityUpdateListener: any;
  private cityRemoveListener: any;

  public isLoggedIn: boolean;
  public states: any = {
    cityReady: false,
    initialMessages: false,
    buildingsReady: false,
    constructing: false,
    unveilAvailable: false,
  };

  constructor(
    firebase: Firebase,
    playcanvas: Playcanvas,
    buildMachine: BuildMachine,
    analytics: Analytics
  ) {
    this.firebase = firebase;
    this.playcanvas = playcanvas;
    this.buildMachine = buildMachine;
    this.analytics = analytics;

    this.current = {
      private: {},
    };
    //this.fm = new Fortmatic("pk_test_3C2DB98793D849FA");
    this.fm = new Fortmatic("pk_live_2DD3FEEF9C44D38B");
    //this.web3 = new Web3(this.fm.getProvider());

    // Post EIP-1102 update which MetaMask no longer injects web3
    if (window.ethereum) {
      // Use MetaMask provider
      this.web3 = new Web3(window.ethereum);
    } else {
      // Use Fortmatic provider
      this.web3 = new Web3(this.fm.getProvider());
    }

    this.sessionID = this.getSessionID();
  }

  getSessionID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  checkLoggedIn = (doPrepare: boolean) => {
    return new Promise(async (resolve) => {
      this.isLoggedIn = await this.fm.user.isLoggedIn();

      if (this.isLoggedIn) {
        // --- check if this is an existing
        const userID: any = await this.checkUserExists();

        if (userID) {
          if (doPrepare === true) {
            if (!this.current || !this.current.publicAddress) {
              await this.prepareUser();

              this.analytics.identifyUser(this.current.private.firebaseID, {
                $email: this.current.private.email,
                $phone: this.current.private.phone,
                $first_name: this.current.fullname,
                USER_ID: this.current.private.firebaseID,
                username: this.current.username,
                address: this.current.publicAddress,
                provider: this.current.private.provider,
              });
            }
          } else {
            this.analytics.identifyUser(userID);
          }
        } else {
          this.isLoggedIn = false;
        }
      }

      resolve();
    });
  };

  prepareUser = () => {
    return new Promise(async (resolve) => {
      const userData: any = await this.fm.user.getUser();

      this.current.private.fortmaticID = userData.userId;
      await this.firebase.doFirebaseAuth(this.current);

      this.maintanance();

      // --- check if we are updating the provider
      switch (this.current.private.provider) {
        case "fortmatic":
          this.web3.setProvider(this.fm.getProvider());
          break;
        case "ethereum":
          if (window.ethereum) {
            this.web3.setProvider(window.ethereum);
          }
          break;
      }

      await this.web3.currentProvider.enable();

      // --- load user tokens
      let address: string = this.current.publicAddress;
      //address = "0x64c971d7e3c0483fa97a7714ec55d1e1943731c7"; // Preston
      //address = "0x9d09b65F15ACcb81e48f61B7b3288ef2927E593f"; // Preston Fortmatic
      //address = "0x442dccee68425828c106a3662014b4f131e3bd9b"; // j1mmy
      //address = "0x6e63a4caeccb4f341ee9c9175c9cc554bdb6d10b"; // WGMeets
      // address = "0x8706473086B6A50CB822394BE3cd3dBd990490eE"; // Jackson

      this.addUserBuildingEvents();

      await this.prepareUserBuildings(address, true);

      await this.buildMachine.prepare();

      console.log(this.current);

      this.isLoggedIn = true;

      this.analytics.track("User", "Signed In");

      resolve();
    });
  };

  doSignUp = (betaKey: string) => {
    return new Promise(async (resolve, reject) => {
      if (!betaKey) {
        window.location.reload();
        return;
      }

      const userData: any = await this.fm.user.getUser();

      // --- check if we are updating the provider
      switch (this.current.private.provider) {
        case "fortmatic":
          this.web3.setProvider(this.fm.getProvider());
          break;
        case "ethereum":
          if (window.ethereum) {
            this.web3.setProvider(window.ethereum);
          } else {
            reject("Wallet provider not found");
          }
      }

      const addresses: string[] = await this.web3.currentProvider.enable();

      this.current.private.email = userData.email ? userData.email : null;
      this.current.private.phone = userData.phone ? userData.phone : null;
      this.current.publicAddress = addresses[0];
      this.current.private.fortmaticID = userData.userId;

      await this.firebase.doFirebaseAuth(this.current);

      await this.consumeBetaKey(betaKey, this.current.private.firebaseID);

      resolve();
    });
  };

  doSignIn = (type: string = "email") => {
    return new Promise(async (resolve, reject) => {
      await this.fm.configure({ primaryLoginOption: type });

      this.fm.user
        .login()
        .then(async () => {
          // --- check if this is a new user or existing
          const userID = await this.checkUserExists();

          this.isLoggedIn = true;

          if (userID) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(reject);
    });
  };

  checkUserExists = () => {
    return new Promise(async (resolve) => {
      const userData: any = await this.fm.user.getUser();

      const userID = this.firebase.checkFirebaseUserExists(userData.userId);

      resolve(userID);
    });
  };

  checkUsernameExists = (username: string) => {
    return this.firebase.checkUsernameExists(username);
  };

  getUserDetails = (id: string) => {
    return this.firebase.getUserDetails(id);
  };

  doSignOut = () => {
    if (this.fm) this.fm.user.logout();
  };

  getBalance(): Promise<IBalance> {
    return new Promise((resolve) => {
      this.web3.eth.getBalance(
        this.current.publicAddress,
        (err: any, balance: any) => {
          const eth: number = parseFloat(
            this.web3.utils.fromWei(balance, "ether")
          );

          const usd: number = eth * this.buildMachine.ethToUsd;

          const userBalance: IBalance = {
            eth: eth,
            usd: usd,
            ethStr: eth.toFixed(7),
            usdStr: usd.toFixed(2),
          };

          resolve(userBalance);
        }
      );
    });
  }

  prepareBuildings(tokensList: number[], parseBuildings: boolean = true) {
    if (!this.tokens) this.tokens = [];
    const tokens: any[] = [];
    const tokensToParse: any[] = [];

    // --- parse the list of Firestore buildings to get references for user's building
    tokensList.forEach((id: any) => {
      const building: any = this.buildMachine.buildings[id];

      if (building) {
        building.id = id;
        tokens.push(building);

        if (building._readyToBuild === false) {
          tokensToParse.push(building);
        }

        // --- add it to the user's tokens list
        if (this.tokens.find((token: any) => token.id === id) === undefined) {
          this.tokens.push(building);
        }
      }
    });

    // --- building ids to load
    if (parseBuildings === true) {
      this.playcanvas.sendMessage(EPlaycanvasEvents.ParseBuildings, {
        tokens: tokensToParse,
      });
    }

    return tokens;
  }

  addUserBuildingEvents() {
    // --- send the buildings for parsing to Playcanvas
    this.playcanvas.on(
      EPlaycanvasEvents.Building_Ready,
      async (payload: any) => {
        let building: any = this.tokens.find(
          (building: any) => building.id === payload.id
        );

        if (building) {
          building._readyToBuild = true;
          building._availableToBuild = true;

          if (building._new === true) {
            building._new = false;

            this.buildMachine.clearMintedToken(building.id);

            this.playcanvas.sendMessage(
              EPlaycanvasEvents.Building_Unveil,
              building.id
            );
          }
        }
      }
    );

    this.playcanvas.once(EPlaycanvasEvents.BuildingsReady, async () => {
      this.states.buildingsReady = true;

      this.playcanvas.once(EPlaycanvasEvents.CityReady, (data: any) => {
        this.states.cityReady = true;

        this.playcanvas.sendMessage(EPlaycanvasEvents.City_PickerState, {
          canSelectActors: true,
          canMoveActors: true,
        });
        this.playcanvas.sendMessage(
          EPlaycanvasEvents.City_SerializeState,
          true
        );
      });

      this.subscribeToCityChanges(this.current.private.firebaseID);

      // --- load the existing city and start listening for serialize events
      await this.loadCityFromDB(this.current.private.firebaseID);
      //await this.loadCityFromDB("M6dNUAKaypULc9NOMr4ZVzr8pEf2"); // Preston

      this.playcanvas.on(EPlaycanvasEvents.City_SaveToDB, (data: any) => {
        this.saveCityToDB(data);
      });
    });
  }

  constructBuilding(tokenID: number) {
    return new Promise(async (resolve) => {
      const response = await fetch(
        `https://us-central1-block-cities.cloudfunctions.net/api/network/1/token/${tokenID}/database/refresh`
      );

      const token = await response.json();

      if (this.tokens) {
        this.playcanvas.once(EPlaycanvasEvents.BuildingsReady, () => {
          resolve();
        });

        const building: any = this.tokens.find(
          (building: any) => building.id === tokenID
        );

        if (!building) {
          token.id = tokenID;
          token._new = true;
          this.tokens.push(token);
        }

        this.playcanvas.sendMessage(EPlaycanvasEvents.ParseBuildings, {
          tokens: [token],
        });
      } else {
        resolve();
      }
    });
  }

  getCityFromDB(id: string) {
    return new Promise((resolve) => {
      this.firebase.database.ref("cities/" + id).once("value", (snapshot) => {
        resolve(snapshot.val());
      });
    });
  }

  async prepareUserBuildings(address: string, parseBuildings: boolean) {
    this.tokens = null;

    const response = await fetch(
      `https://us-central1-block-cities.cloudfunctions.net/api/network/1/token/account/${address}/tokens-id`
    );

    const tokensList = await response.json();

    let tokens;

    if (tokensList.length > 0) {
      tokens = this.prepareBuildings(tokensList, parseBuildings);
    } else {
      this.playcanvas.sendMessage(EPlaycanvasEvents.BuildingsReady);
      tokens = [];
    }

    console.log(tokensList);
    return tokens;
  }

  prepareCityBuildingsFromDB(id: string) {
    return new Promise(async (resolve) => {
      const city: any = await this.getCityFromDB(id);

      // --- get a list with city's buildings
      const buildingIDs = [];

      for (const key in city) {
        const item = city[key];

        if (item.type === "building") {
          // Legacy, actorType
          const actorType = item.actorType ? item.actorType : item.building;

          buildingIDs.push(actorType);
        }
      }

      // --- load and prepare these buildings
      this.playcanvas.once(EPlaycanvasEvents.BuildingsReady, async () => {
        this.playcanvas.sendMessage(EPlaycanvasEvents.City_LoadFromDB, {
          id: id,
          cityData: city,
        });

        resolve();
      });

      this.prepareBuildings(buildingIDs);
    });
  }

  loadCityFromDB(id: string) {
    return new Promise(async (resolve) => {
      const city = await this.getCityFromDB(id);

      this.playcanvas.sendMessage(EPlaycanvasEvents.City_LoadFromDB, {
        id: id,
        cityData: city,
      });

      resolve();
    });
  }

  subscribeToCityChanges(id: string) {
    this.unSubscribeToCityChanges();

    const cityRef = this.firebase.database.ref("cities/" + id + "/");

    this.cityAddListener = cityRef.limitToLast(1).on("child_added", (data) => {
      if (!this.states.cityReady) return;

      if (data.val().sessionID !== this.sessionID) {
        const payload = data.val();
        payload._key = data.key;

        // --- check if we have a building that needs parsing before adding it
        // Legacy, actorType
        const actorType = payload.actorType
          ? payload.actorType
          : payload.building;

        if (
          payload.type === "building" &&
          this.buildMachine.buildings[actorType]._readyToBuild === false
        ) {
          this.playcanvas.once(EPlaycanvasEvents.BuildingsReady, () => {
            this.playcanvas.sendMessage(
              EPlaycanvasEvents.CityView_ActorAdded,
              payload
            );
          });

          this.prepareBuildings([actorType]);
        } else {
          this.playcanvas.sendMessage(
            EPlaycanvasEvents.CityView_ActorAdded,
            payload
          );
        }
      }
    });

    this.cityUpdateListener = cityRef.on("child_changed", (data) => {
      if (!this.states.cityReady) return;

      if (data.val().sessionID !== this.sessionID) {
        const payload = data.val();
        payload._key = data.key;

        this.playcanvas.sendMessage(
          EPlaycanvasEvents.CityView_ActorUpdated,
          payload
        );
      }
    });

    this.cityRemoveListener = cityRef.on("child_removed", (data) => {
      if (!this.states.cityReady) return;

      this.playcanvas.sendMessage(
        EPlaycanvasEvents.CityView_ActorRemoved,
        data.key
      );
    });
  }

  unSubscribeToCityChanges() {
    if (
      this.cityAddListener &&
      typeof this.cityAddListener.unsubscribe === "function"
    )
      this.cityAddListener.unsubscribe();
    if (
      this.cityUpdateListener &&
      typeof this.cityUpdateListener.unsubscribe === "function"
    )
      this.cityUpdateListener.unsubscribe();
    if (
      this.cityRemoveListener &&
      typeof this.cityRemoveListener.unsubscribe === "function"
    )
      this.cityRemoveListener.unsubscribe();
  }

  async saveCityToDB(data: any) {
    const cityPath = `cities/${this.current.private.firebaseID}/`;

    switch (data.state) {
      case "add":
        // --- add user to data
        data.data.uid = this.current.private.firebaseID;
        data.data.sessionID = this.sessionID;

        // --- clear all values with same pos guid first, to avoid duplicates due to network sync issues
        const snapshot = await this.firebase.database
          .ref(cityPath)
          .orderByChild("pos")
          .equalTo(data.data.pos)
          .once("value");

        for (const duplicateKey in snapshot.val()) {
          await this.firebase.database
            .ref(`${cityPath}${duplicateKey}`)
            .set(null);
        }

        if ((await this.firebase.checkKeyExists(cityPath, data.key)) === null) {
          const snapshot = await this.firebase.database
            .ref(cityPath)
            .push(data.data);

          this.playcanvas.sendMessage(EPlaycanvasEvents.Actor_UpdateKey, {
            oldKey: data.key,
            key: snapshot.key,
          });
        }
        break;
      case "update":
        // --- add user to data
        data.data.push({
          property: "uid",
          value: this.current.private.firebaseID,
        });
        data.data.push({ property: "sessionID", value: this.sessionID });

        data.data.forEach((action: any) => {
          this.firebase.database
            .ref(`${cityPath}${data.key}/${action.property}`)
            .set(action.value);
        });
        break;
      case "remove":
        this.firebase.database
          .ref("cities/" + this.current.private.firebaseID + "/")
          .child(data.key)
          .remove();
        break;
    }
  }

  parseBuildingID(id: any) {
    const parsed: any = id.replace("#", "");
    return parseInt(parsed);
  }

  getUserToken(id: any) {
    if (!id) return;

    let token;
    if (this.tokens) {
      token = this.tokens.find((token: any) => {
        return token.id === id;
      });
    }

    if (token) {
      return token;
    } else {
      this.prepareBuildings([id]);

      token = this.tokens.find((token: any) => {
        return token.id === id;
      });

      return token;
    }
  }

  async checkBetaKey(betaKey: string) {
    const response = await fetch(
      `https://us-central1-block-cities.cloudfunctions.net/api/events/betakey/valid/${betaKey}`
    );

    let data = await response.json();

    if (data && data.valid !== undefined) {
      return data.valid;
    } else {
      return false;
    }
  }

  async consumeBetaKey(betaKey: string, userID: string) {
    await fetch(
      `https://us-central1-block-cities.cloudfunctions.net/api/events/betakey/consume/${betaKey}/${userID}`
    );

    return;
  }

  async maintanance() {
    // --- ADMIN maintanance scripts ---
    // const snapshot = await this.firebase.database.ref("cities").once("value");
    // const cities = snapshot.val();
    // const getGridPosGuid = (gridPos: any) => {
    //   return `${gridPos.x}_${gridPos.y}_${gridPos.z}`;
    // };
    // for (const cityID in cities) {
    //   //if (cityID !== "BVFbp49LfRZARXiwzmbeP2RtLXa2") continue;
    //   console.log("cityID:", cityID);
    //   const city = cities[cityID];
    //   const entries: any = {};
    //   // --- find duplicates per cell guid
    //   for (const key in city) {
    //     const entry = city[key];
    //     const guid = getGridPosGuid(entry.pos);
    //     if (key) {
    //       console.log("updating");
    //       if (entry.type === "building" && entry.building) {
    //         await this.firebase.database
    //           .ref(`cities/${cityID}/${key}/actorType`)
    //           .set(entry.building);
    //         await this.firebase.database
    //           .ref(`cities/${cityID}/${key}/building`)
    //           .set(null);
    //       }
    //       if (entry.type === "road" && entry.road) {
    //         await this.firebase.database
    //           .ref(`cities/${cityID}/${key}/actorType`)
    //           .set(entry.road);
    //         await this.firebase.database
    //           .ref(`cities/${cityID}/${key}/road`)
    //           .set(null);
    //       }
    //       await this.firebase.database
    //         .ref(`cities/${cityID}/${key}/pos`)
    //         .set(guid);
    //     }
    //     // --- assemble duplicates per pos guid
    //     // if (!entries[guid]) entries[guid] = [];
    //     // entry.key = key;
    //     // entries[guid].push(entry);
    //   }
    //   // --- clean up duplicates, keep the last value
    //   // for (const guid in entries) {
    //   //   if (entries[guid].length > 1) {
    //   //     entries[guid].pop();
    //   //     for (const entry of entries[guid]) {
    //   //       console.log(cityID, entry.key);
    //   //       await this.firebase.database
    //   //         .ref(`cities/${cityID}/${entry.key}`)
    //   //         .set(null);
    //   //     }
    //   //   }
    //   // }
    // }
    // console.log("--- finished");
  }

  // walletSend(address: string, amount: string) {
  //   return new Promise(resolve => {
  //     if (!amount || !address) {
  //       resolve(false);
  //       return;
  //     }

  //     // Construct Ether transaction params
  //     const txnParams = {
  //       from: this.current.publicAddress,
  //       to: address,
  //       value: this.web3.utils.toWei(amount, "ether")
  //     };

  //     // Send Ether transaction with web3
  //     this.web3.eth.sendTransaction(txnParams, (error: any, txnHash: any) => {
  //       if (error) {
  //         resolve(false);
  //         return;
  //       }

  //       resolve(true);
  //     });
  //   });
  // }
}

export default User;
