import React from "react";
import "./City.css";

import { RouteProps } from "react-router";

export interface CityStatsProps extends RouteProps {
  buildings: number;
  roads: number;
  roadsLength: number;
}

const CityStats: React.FC<CityStatsProps> = props => {
  return (
    <div>
      <div className="counter-div buildings">
        <img
          className="counter-icon"
          src={require("../images/building-icon.png")}
        ></img>
        <p className="counter-text">{props.buildings}</p>
      </div>

      <div className="counter-div roads">
        <img
          className="counter-icon"
          src={require("../images/road-icon.png")}
        ></img>
        <p className="counter-text">{props.roadsLength.toFixed(1)}</p>
        <p className="counter-text small">mi.</p>
      </div>
      {/* <div className="counter-div trees">
    <img
      className="counter-icon"
      src={require("../images/tree-icon.png")}
    ></img>
    <p className="counter-text">5</p>
  </div> */}
    </div>
  );
};

export default CityStats;
