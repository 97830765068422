import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonRouterLink,
  useIonViewWillEnter,
} from "@ionic/react";
import "./UserProfile.css";

import BuildingProfile from "./BuildingProfile";
import PublicNavButtons from "./PublicNavButtons";

import User, { UserContext } from "../components/user";
import { IBalance, IUserData } from "../components/user/user";

import * as routes from "../constants/routes";

const UserProfile: React.FC = (props: any) => {
  const user: User = useContext(UserContext);

  const { nickname } = useParams();

  const [balance, setBalance] = useState({
    eth: 0.0,
    usd: 0.0,
    ethStr: "",
    usdStr: "",
  });

  const [mode, setMode] = useState("full");
  const [userDetails, setUserDetails] = useState<IUserData>({});
  const [buildings, setBuildings] = useState([]);

  const [buildingProfileOpen, setBuildingProfileOpen] = useState(false);
  const [buildingProfileID, setBuildingProfileID] = useState(null);

  useIonViewWillEnter(() => {
    // --- we fallback to public address if nothing else is provided
    if (!nickname && user.isLoggedIn === false) {
      setMode("publicAddress");

      user.web3.currentProvider.enable().then(async (addresses: any) => {
        console.log(addresses);

        const userBuildings = await user.prepareUserBuildings(
          addresses[0],
          false
        );

        setBuildings(userBuildings ? userBuildings : []);
      });

      return;
    }

    if (nickname) {
      setMode("read");

      user.checkUsernameExists(nickname).then(async (userID) => {
        if (!userID) return;

        const details: IUserData = await user.getUserDetails(userID as string);
        setUserDetails(details);

        const userBuildings = await user.prepareUserBuildings(
          details.publicAddress,
          false
        );

        user.analytics.track(
          "UserProfile",
          `Loaded Profile for user ${nickname}.`,
          {
            id: userID,
          }
        );

        setBuildings(userBuildings ? userBuildings : []);
      });
    } else {
      setMode("full");
      setBuildings(user.tokens ? user.tokens : []);
      setUserDetails(user.current);

      user.getBalance().then((balance: IBalance) => {
        setBalance(balance);
      });
    }
  });

  return (
    <IonPage>
      <IonContent scroll-y="true">
        <BuildingProfile
          open={buildingProfileOpen}
          buildingID={buildingProfileID}
          setOpenState={setBuildingProfileOpen}
          useModal={true}
        />
        <div className="max-width">
          <IonHeader className="header">
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="6" className="no-padding">
                  {mode !== "publicAddress" ? (
                    <IonRouterLink
                      routerLink={
                        mode === "full" ? routes.CITY : `/city/${nickname}`
                      }
                      routerDirection="back"
                    >
                      <IonButton className="back-button no-padding">
                        <img
                          className="back-arrow"
                          src={require("../images/side-arrow.svg")}
                        ></img>
                      </IonButton>
                    </IonRouterLink>
                  ) : (
                    <div></div>
                  )}
                </IonCol>

                <IonCol size="3.5">
                  {mode === "full" ? (
                    <IonRouterLink routerLink={routes.SETTINGS}>
                      <img
                        className="settings-icon"
                        src={require("../images/settings-icon.svg")}
                      ></img>
                    </IonRouterLink>
                  ) : (
                    <div></div>
                  )}
                </IonCol>

                <IonCol size="2.5" className="no-padding">
                  {mode === "full" ? (
                    <IonRouterLink routerLink={routes.WALLET}>
                      <IonButton fill="outline" className="wallet-button">
                        ${balance.usdStr}
                      </IonButton>
                    </IonRouterLink>
                  ) : (
                    <div></div>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>
          <IonGrid className="no-padding">
            <IonRow className="no-padding"></IonRow>
          </IonGrid>

          {mode !== "publicAddress" ? (
            <div>
              <h1 className="user-full-name">{userDetails.fullname}</h1>

              <h3 className="user-name">@{userDetails.username}</h3>
            </div>
          ) : (
            <div>
              <h1 className="user-full-name"> </h1>

              <h3 className="user-name"> </h3>
            </div>
          )}

          {/* <img
          className="badge profile"
          src="https://i.ibb.co/s53TZJp/1transit-special-edition-5x-1.png"
        />

        <img
          className="badge profile"
          src="https://i.ibb.co/s53TZJp/1transit-special-edition-5x-1.png"
        />

        <img
          className="badge profile"
          src="https://i.ibb.co/s53TZJp/1transit-special-edition-5x-1.png"
        /> */}

          {/* <p className="user-profile-title">City</p>

          <div className="city-card">
            <div className="card-textbox">
              <p className="city-title">{userDetails.username}'s City</p>
            </div>
          </div> */}

          {/* <p className="user-profile-title">Buildings</p> */}

          {mode === "publicAddress" ? (
            <div className="public-nav-buttons">
              <PublicNavButtons currentPath={props.location} />
            </div>
          ) : (
            <div></div>
          )}

          <IonGrid className="no-padding">
            <IonRow className="no-padding" justify-content="space-between">
              {buildings.map((building: any) => {
                return (
                  <IonCol className="no-padding" size="6">
                    <div
                      className="building-card"
                      style={{
                        backgroundColor: "#" + building.background_color,
                      }}
                      onClick={() => {
                        setBuildingProfileOpen(true);
                        setBuildingProfileID(building.id);
                      }}
                    >
                      <img
                        className="building-card-img"
                        src={building.imageStore}
                      />

                      <div className="building-card-textbox">
                        <p className="building-title">
                          {building.attributes.building}
                        </p>
                        <p className="city-sub">{building.attributes.city}</p>
                      </div>
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserProfile;
