import React from "react";

import { IonRouterLink } from "@ionic/react";
import * as routes from "../constants/routes";
import { RouteProps } from "react-router";

import "./PublicNavButtons.css";

export interface PublicNavProps extends RouteProps {
  currentPath: any;
  setStateModal?: Function;
}

const PublicNavButtons: React.FC<PublicNavProps> = (props) => {
  return (
    <div className="kiosk-nav-bar">
      <div className="nav-div">
        <a
          href="https://discord.gg/84JrveD"
          target="_blank"
          className="nav-text-link w-inline-block button-join-beta"
        >
          <div className="nav-text">Join Beta</div>
        </a>
        <IonRouterLink
          routerLink="/user/"
          className="nav-text-link w-inline-block"
          onClick={() => {
            if (props.setStateModal) {
              props.setStateModal(false);
            }
          }}
        >
          <div className="nav-text">My Buildings</div>
        </IonRouterLink>
      </div>
      <a
        href="https://www.blockcities.build"
        target="_blank"
        className="nav-div middle w-inline-block"
      >
        <img className="image-65" src={require("../images/Slice_2.png")}></img>
      </a>
      <div className="nav-div right">
        {/* <IonRouterLink
          routerLink={routes.MYSTERY_MACHINE}
          className="main-nav-button rainbow w-inline-block"
          onClick={() => {
            if( props.setStateModal ){
            props.setStateModal(false);
            }
          }}
        >
          <img
            className="image-97"
            src={require("../images/pink-modern.png")}
          ></img>
        </IonRouterLink> */}
        {props.currentPath.pathname !== routes.BROOKLYN_KIOSK ? (
          <IonRouterLink
            routerLink={routes.BROOKLYN_KIOSK}
            className="main-nav-button w-inline-block"
            onClick={() => {
              if (props.setStateModal) {
                props.setStateModal(false);
              }
            }}
          >
            <img
              className="image-97"
              src={require("../images/brooklyn-icon.png")}
            ></img>
          </IonRouterLink>
        ) : (
          <div className="main-nav-button w-inline-block">
            <img
              className="image-97"
              src={require("../images/brooklyn-icon.png")}
            ></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicNavButtons;
